$col-width-xs: "(50vw - 3.6rem)";
$col-width-md: 14.1rem;
$col-width-xl: 21rem;
$col-width-xxl: 23.7rem;
$half-gutter-xs: 1.2rem;
$half-gutter-md: (7.3rem / 2);
$half-gutter-xl: (9.7rem / 2);
$half-gutter-xxl: (10.5rem / 2);

.staff {
  @include xs {
    margin-top: 11.2rem;
  }

  .backdrop {
    background: linear-gradient(to top, $gray-green calc(#{$col-width-xs} + 15.6rem), $beige calc(#{$col-width-xs} + 15.6rem));
    position: absolute;
    top: calc(#{$col-width-xs} * 0.5);
    bottom: calc(#{$col-width-xs} * 0.5 + 11.6rem);
    width: 100vw;
    left: calc(50% - 50vw);

    @include md {
      background: linear-gradient(to left, $gray-green 27.4%, $beige 27.4%);
      width: auto;
      top: $col-width-md * 0.5;
      bottom: $col-width-md * 0.5 + 13.2rem;
      left: -5%;
      right: -5%;
    }

    @include xl {
      top: $col-width-xl * 0.5;
      bottom: $col-width-xl * 0.5 + 14.4rem;
    }

    @include xxl {
      top: $col-width-xxl * 0.5;
      bottom: $col-width-xxl * 0.5 + 14.4rem;
    }
  }

  .h4 {
    margin: 1.6rem 0 0.8rem;

    @include md {
      margin: 2.8rem 0 1rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 1.8rem;
      line-height: (26 / 18);
    }
  }

  .small {
    min-height: 4rem;
  }

  .row {
    margin-left: -$half-gutter-xs;
    margin-right: -$half-gutter-xs;

    @include md {
      margin-top: 6rem;
      margin-left: -$half-gutter-md;
      margin-right: -$half-gutter-md;
    }

    @include xl {
      margin-left: -$half-gutter-xl;
      margin-right: -$half-gutter-xl;
    }

    @include xxl {
      margin-left: -$half-gutter-xxl;
      margin-right: -$half-gutter-xxl;
    }
  }

  .col-5 {
    padding: 0 $half-gutter-xs;

    @include md {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 $half-gutter-md;
    }

    @include xl {
      padding: 0 $half-gutter-xl;
    }

    @include xxl {
      padding: 0 $half-gutter-xxl;
    }

    &:nth-child(n + 3) {
      @include xs {
        margin-top: 4rem;
      }
    }

    &:nth-child(n + 5) {
      @include md {
        margin-top: 8rem;
      }
    }
  }

  figcaption {
    @include md {
      right: 0;
      bottom: calc(100% - 6rem);
      transform: rotate(-90deg);
      transform-origin: bottom right;
    }
  }
}
