.img-cover {
  object-fit: cover;
  object-position: 50% 50%;
}

.img-contain {
  object-fit: contain;
  object-position: 50% 50%;
}

.backdrop {
  position: absolute;
  z-index: -1;
}

.columns {
  @include md {
    column-gap: 2rem;
    columns: 2;

    p,
    ul {
      break-inside: avoid;
    }
  }

  @include xl {
    column-gap: 4rem;
  }
}

%hr {
  @include md {
    content: "";
    display: block;
    width: 12rem;
    height: 1px;
    background: $gray-200;
    margin: 12rem auto;
  }
}

.hr-before {
  &::before {
    @extend %hr;
  }

  &:first-child::before {
    content: none;
  }
}

.hr-after + * {
  &::before {
    @extend %hr;
  }
}

.social-link {
  display: flex;
  width: 1em;
  height: 1em;
  font-size: 5.4rem;

  &:hover,
  &:focus {
    background: $white;
  }

  svg {
    margin: auto;
    width: auto;
    height: (1em / 3);
  }
}

.square {
  padding-top: 100%;
}

.sideways-md {
  cursor: vertical-text;
}

.img-bounds {
  overflow: hidden;

  img {
    @include transition(transform $t);

    a:hover &,
    a:focus & {
      transform: scale(1.05);
    }
  }
}
