.hero {
  h1 {
    @include md {
      width: 55rem;
    }

    @include xl {
      width: 67rem;
    }

    @include xxl {
      width: 75rem;
    }

    .pages-basic & {
      @extend .h2;

      margin-bottom: 8rem;

      @include md {
        margin-bottom: 9rem;
      }
    }
  }

  .body {
    @include md {
      width: 28rem;
    }

    @include xl {
      width: 44rem;
    }

    @include xxl {
      width: 47.5rem;
    }
  }
}

.hero-carousel {
  .body {
    width: 80%;

    @include md {
      width: 20.5rem;
    }

    @include xl {
      width: 32.5rem;
    }

    @include xxl {
      width: 35rem;
    }
  }
}

.hero-image,
.hero-carousel {
  @include md {
    position: relative;
    padding-top: 6rem;

    > figure,
    > .carousel {
      top: 0;
    }
  }
}

.hero-image {
  .container {
    @include md {
      min-height: 76rem;
    }
  }

  figure {
    margin: 0 2.4rem;

    @include md {
      position: absolute;
      margin: 0;
      right: 4rem;
    }

    @include xl {
      right: 7.6rem;
    }
  }

  img {
    height: 37.2rem;
    width: 100%;

    @include md {
      height: 72rem;
      max-width: 51vw;
    }

    @include xl {
      max-width: 44vw;
    }
  }

  figcaption {
    @include md {
      position: absolute;
      transform: rotate(-90deg) translate(50%, -100%);
      transform-origin: top right;
      right: 0;
    }
  }

  .backdrop {
    width: 92%;
    height: 32rem;
    top: 9.2rem;
    right: -4rem;

    @include md {
      width: 88%;
      height: 85%;
      top: auto;
      bottom: -10rem;
    }

    @include xl {
      right: -3.6rem;
    }
  }
}

.hero-carousel .container {
  @include md {
    min-height: 50rem;
  }
}
