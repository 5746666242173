body {
  @include media-breakpoint-down(lg) {
    font-size: 1.6rem;
  }
}

a {
  @include transition(color $t, background $t, border $t);

  &:hover,
  &:focus {
    color: $link-hover-color !important;
  }
}

.nav-link,
a:not([class]) {
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    border-bottom-color: $link-hover-color;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  letter-spacing: -0.05em;
}

h1,
.h1 {
  @include xs {
    font-size: 4.4rem;
    line-height: (56 / 44);
  }

  @include md {
    margin-bottom: 4rem;
  }

  @include xl {
    font-size: 10rem;
  }
}

h2,
.h2 {
  @include xs {
    font-size: 4.4rem;
    line-height: (56 / 44);
  }
}

h3,
.h3 {
  line-height: (56 / 44);

  @include md {
    margin-bottom: 3.6rem;
  }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.5;
}

h5,
.h5 {
  margin-bottom: 1.6rem;
}

h6,
.h6 {
  margin-bottom: 1.2rem;

  &.text-uppercase {
    letter-spacing: 0.04em;
  }
}

.kirbytext {
  h1 {
    @extend .h3;

    &:not(:first-child) {
      margin-top: 4.8rem;
    }
  }

  h2 {
    @extend .h4;

    &:not(:first-child) {
      margin-top: 4.8rem;
    }
  }

  h3 {
    @extend .h5;

    &:not(:first-child) {
      margin-top: 4rem;
    }
  }
}

small,
.small {
  line-height: (20 / 14);
}

.smaller {
  font-size: 1.6rem;
}

ul,
ol {
  margin: 0 0 $paragraph-margin-bottom;
  padding: 0;
}

li {
  margin-bottom: 0.75em;
  padding-left: 1.25em;
  position: relative;
}

ul {
  list-style: none;

  li::before {
    content: "•";
    color: $red-brown;
    position: absolute;
    left: 0;
  }
}

p,
ul,
ol {
  &:last-child {
    margin-bottom: 0;
  }
}

hr {
  @extend %hr;
}

img {
  max-width: 100%;
}

figure {
  position: relative;
  margin: 0;
}

figcaption {
  font-family: $font-family-alt;
  font-weight: bold;
  letter-spacing: 0.2em;
  line-height: 1.5;
  padding: 1.8rem 0;
  text-transform: uppercase;
  white-space: nowrap;

  @include xl {
    padding: 2.8rem 0;
  }

  @include xs {
    font-size: 1.2rem;
    padding: 3rem 0;
  }
}

.btn-wrap {
  margin-top: 2.4rem;
}
