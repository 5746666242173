.overlay {
  @include transition(
    opacity ($overlay-t * 0.4) ($overlay-t * 0.6),
    background-position $overlay-t
  );
  pointer-events: none;
  opacity: 0;
  background: -200% 0 / 200% 100% linear-gradient(to right, rgba($light, 0) 47%, $light 50%);

  > * {
    transition: opacity $overlay-t * 0.5 !important;
    opacity: 0;
  }

  .overlay-shown & {
    @include transition(
      opacity ($overlay-t * 0.4),
      background-position $overlay-t
    );

    pointer-events: auto;
    opacity: 1;
    background-position: -100% 0;

    > * {
      transition: opacity $overlay-t ($overlay-t * 0.5) !important;
      opacity: 1;
    }
  }

  .menu-link {
    position: relative;
    line-height: 1.03;

    &::after {
      @include transition(opacity $t, bottom $t);

      content: "";
      position: absolute;
      z-index: -1;
      opacity: 0;
      bottom: 0;
      left: 0;
      right: -0.25em;
      height: 0.2em;
      background: currentColor;
    }

    &:hover,
    &:focus {
      &::after {
        opacity: 1;
        bottom: 0.125em;
      }
    }

    @include xs {
      font-size: 5.2rem;

      &::after {
        right: -0.1em;
      }
    }
  }

  .navbar-brand {
    background: $white;
  }

  @include md {
    .social-links {
      position: absolute;
      right: 4rem;
    }

    .social-link {
      font-size: 7.2rem;
    }
  }

  :last-child .menu-link {
    margin-bottom: 0;
  }

  .container {
    padding-bottom: 7vh;

    @include md {
      padding-bottom: 5rem;
      height: 100%;
    }

    // iOS Safari bug
    @include xs {
      position: absolute;
      top: $navbar-height-xs;
      bottom: 0;
    }
  }
}

.navbar-height-xs {
  @include xs {
    min-height: $navbar-height-xs;
  }
}

$colors: (
  beige: $beige,
  teal: $teal,
  cream: $cream,
  peach: $peach
);

@each $color, $value in $colors {
  .nav-#{$color}::after {
    color: $value;
  }
}
