.accordion {
  &:not(:first-child) {
    margin-top: 12rem;
  }

  .btn {
    position: relative;
    margin-right: 1.6rem;

    @include md {
      margin-right: 2.8rem;
      top: 0.2rem;
    }

    @include xs {
      font-size: 2.8rem;
      top: -0.2rem;
    }
  }

  &-style-teal {
    .btn:hover,
    .btn:focus,
    a:hover .btn,
    a:focus .btn {
      background: $cyan;
    }
  }

  [aria-expanded="true"] .btn {
    color: $white !important;
    background: $body-color !important;
  }

  .minus {
    display: none;
  }

  [aria-expanded="true"] {
    .minus {
      display: block;
    }

    .plus {
      display: none;
    }
  }

  .section:not(:last-child) {
    margin-bottom: 2.8rem;

    @include md {
      margin-bottom: 3.2rem;
    }
  }

  .collapse-inner {
    padding: 1.6rem 0 0 4.4rem;

    @include md {
      padding: 2.4rem 0 0 6.4rem;
    }

    &::after {
      content: "";
      display: block;
      width: 12rem;
      height: 1px;
      background: $hr-border-color;
      margin-top: 2.4rem;

      @include md {
        margin-top: 3.2rem;
      }
    }
  }
}
