.projects {
  margin-top: 8rem;

  &:first-child {
    margin-top: -4rem;

    @include md {
      margin-top: -25rem;
    }

    @include xl {
      margin-top: -30.8rem;
    }
  }
}

.project {
  display: flex;
  margin-top: 12rem;
  justify-content: center;

  .h3 {
    margin-top: 2.4rem;

    @include md {
      margin-top: 2.8rem;
    }

    @include xl {
      margin-top: 3.2rem;
    }

    @include xs {
      line-height: 1.5;
      font-size: 2.4rem;
    }
  }

  figcaption {
    padding: 0.8rem 0 0;

    @include md {
      padding: 1.6rem 0 0;
    }

    @include xl {
      padding: 2rem 0 0;
    }
  }
}

$styles: (
  //  Alignment   Backdrop      Caption     Margin
  1: (flex-end,   $gray-green,  $red-brown, 16rem),
  2: (flex-start, $cyan,        $teal,      16rem),
  3: (flex-end,   $peach-light, $red,       -25.6rem),
  4: (flex-start, $beige,       $orange,    19rem),
  5: (flex-end,   $green-light, $green,     16rem),
  6: (flex-start, $yellow,      $peach,     16rem),
  7: (flex-end,   $gray-200,    $maroon,    -31.5rem),
  8: (flex-start, $teal,        $indigo,    19rem)
);

$images: (
  //  Width     Height-xs Height  Left
  1: (59.8rem,  24vw,     44rem,  8rem),
  2: (44rem,    32.7vw,   44rem,  -7.7rem),
  3: (44rem,    43.1vw,   58rem,  0rem),
  4: (67rem,    21.5vw,   44rem,  8rem),
  5: (59.8rem,  24vw,     44rem,  8rem),
  6: (44rem,    32.7vw,   44rem,  -7.7rem),
  7: (44rem,    43.1vw,   58rem,  0rem),
  8: (67rem,    21.5vw,   44rem,  8rem),
);

$backdrops: (
  //  Bd-left-xs            Bd-left             Bd-right-xs           Bd-right            Bd-height-xs  Bd-height Bd-bot-xs Bd-bot
  1: (-2.4rem,              -10rem,             -4rem,                6.4rem,             42vw,         28rem,    -4.4rem,  -12rem),
  2: (calc(100% - 5.6rem),  calc(100% - 8rem),  -2.4rem,              -4rem,              8rem,         12rem,    -2.4rem,  -4rem),
  3: (-4rem,                -6rem,              -7rem,                -90%,               64vw,         40rem,    -4.4rem,  -12rem),
  4: (-2.4rem,              -8rem,              calc(100% - 11.6rem), calc(100% - 20rem), 14rem,        28rem,    -4.4rem,  -12rem),
  5: (-2.4rem,              -10rem,             -4rem,                6.4rem,             42vw,         28rem,    -4.4rem,  -12rem),
  6: (calc(100% - 5.6rem),  calc(100% - 8rem),  -2.4rem,              -4rem,              8rem,         12rem,    -2.4rem,  -4rem),
  7: (-4rem,                -6rem,              -7rem,                -90%,               64vw,         40rem,    -4.4rem,  -12rem),
  8: (-2.4rem,              -8rem,              calc(100% - 11.6rem), calc(100% - 20rem), 14rem,        28rem,    -4.4rem,  -12rem)
);

// Height-xs / 37.5
// Left / 113

@each $i, $style in $styles {
  $image:     map-get($images,    $i);
  $backdrop:  map-get($backdrops, $i);

  .project-style-#{$i} {
    @include md {
      justify-content: nth($style, 1);
      margin-top: nth($style, 4);
    }

    figure {
      @include md {
        min-width: (nth($image, 1) / 113rem * 100%);
        max-width: (nth($image, 1) / 113rem * 100%);
        left: (nth($image, 4) / 113rem * 100%);
      }
    }

    img {
      height: (nth($image, 2) / 37.5 * 100);

      @include md {
        height: (nth($image, 3) * 0.695);
      }

      @include xl {
        height: nth($image, 3);
      }
    }

    .backdrop {
      background: nth($style, 2);
      left: nth($backdrop, 1);
      right: nth($backdrop, 3);
      height: nth($backdrop, 5);
      bottom: nth($backdrop, 7);

      @include md {
        left: nth($backdrop, 2);
        right: nth($backdrop, 4);
        height: nth($backdrop, 6);
        bottom: nth($backdrop, 8);
      }
    }

    figcaption {
      color: nth($style, 3);
    }
  }
}
