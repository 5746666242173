.nav-header {
  margin-bottom: 4.8rem;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }

  @include md {
    margin-bottom: 10rem;
  }

  .pages-basic & {
    margin-bottom: 6.8rem;

    @include md {
      margin-bottom: 16rem;
    }
  }
}

.navbar-brand {
  background: $light;
  padding: 4rem;
  border: 0 !important;

  @include xs {
    padding: 2.1rem 2.4rem;

    svg {
      height: 1.65rem;
    }
  }

  &,
  h1 {
    line-height: 1px;
  }
}

.navbar-toggler {
  @include transition(background $t);

  border: 0;
  display: flex;
  width: 7.2rem;
  height: 7.2rem;

  @include xs {
    width: 5rem;
    height: 5rem;
  }

  svg {
    @include transition(transform $t);

    margin: auto;
  }
}

.navbar-hamburger {
  &:hover,
  &:focus {
    background: $light;

    svg {
      transform: scaleY(1.4);
    }
  }

  @include xs {
    svg {
      height: 1.8rem;
    }
  }
}

.navbar-close {
  &:hover,
  &:focus {
    background: $white;

    svg {
      transform: scale(1.2);
    }
  }

  @include xs {
    svg {
      height: 2.4rem;
    }
  }
}

.breadcrumbs {
  margin: 6.8rem 0 3.2rem;

  @include md {
    position: relative;
    margin: 0 0 2rem;
    top: -4.2rem;
  }
}
