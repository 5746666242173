html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;

  // Scale down for tablets.
  @media (min-width: 768px) and (max-width: 999px) {
    font-size: (768px / 1000px) * 10px;
  }

  // Scale up for extra-wide screens.
  @media (min-width: 1900px) {
    font-size: (1900px / 1580px) * 10px;
  }
}

.wrap {
  overflow-x: hidden;
}

.container {
  @include xs {
    padding-left: $container-gutter-xs;
    padding-right: $container-gutter-xs;

    .container {
      padding-left: $grid-gutter-width * 0.5;
      padding-right: $grid-gutter-width * 0.5;
    }
  }

  @media (min-width: 1900px) {
    max-width: 1544px;
  }
}

.hug {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.hug-xs {
  @include xs {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.hug-md {
  @include md {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}
