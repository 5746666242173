$colors: (
  maroon: $maroon,
  red: $red,
  red-brown: $red-brown,
  yellow: $yellow,
  peach: $peach,
  peach-light: $peach-light,
  gold: $orange,
  beige: $beige,
  gray-pink: $gray-pink,
  gray-green: $gray-green,
  gray-warm: $gray-200,
  gray-dark: $gray-700,
  green: $green,
  green-light: $green-light,
  cream: $cream,
  bright-teal: $cyan,
  teal: $teal,
  blue: $blue,
  indigo: $indigo,
);

@each $color, $value in $colors {
  .text-#{$color} {
    color: $value;
  }

  .bg-#{$color} {
    background-color: $value;
  }

  .border-#{$color} {
    border-color: $value;
  }
}
