.heading-button {
  margin-top: 12rem;

  &.style-peach .heading-button-inner {
    padding: 6rem 0 8rem;
  }

  .backdrop {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, $peach-light (215 / 375 * 100%), $beige (215 / 375 * 100%));
  }

  .h3 {
    margin-bottom: 0;
  }

  &.style-plain {
    .h3 {
      margin-bottom: 4.8rem;
    }
  }

  &.style-peach {
    .btn {
      position: absolute;
      top: 100%;
      transform: translate(-50%, -50%);
    }
  }
}
