.image-text {
  margin-top: 12rem;

  figcaption {
    position: absolute;
  }

  @include xs {
    figure {
      margin-bottom: 8rem;
    }

    figcaption {
      margin-top: 4rem;
      padding: 0;
      transform: translateY(-50%);
    }
  }

  @include md {
    + .image-text {
      margin-top: 16rem;
    }
  }
}

.image-text-left {
  img {
    height: 30.3rem;
  }

  .backdrop {
    bottom: -4rem;
    height: 28rem;
    left: (-48 / 327) * 100%;
    width: 100%;
  }

  @include md {
    figure {
      left: -4rem;
      margin-bottom: 6rem;
    }

    img {
      height: 44rem;
    }

    figcaption {
      bottom: 0;
      transform: rotate(-90deg);
      transform-origin: bottom left;
    }

    .backdrop {
      bottom: -6rem;
      height: 38rem;
      left: (-113 / 542) * 100%;
    }
  }
}

.image-text-right {
  img {
    height: 41.2rem;
  }

  figcaption {
    right: 0;
  }

  .backdrop {
    bottom: -4rem;
    height: 36rem;
    left: (207 / 327) * 100%;
    width: 100%;
  }

  @include md {
    figure {
      left: 7rem;
      margin-bottom: 6rem;
    }

    img {
      height: 52rem;
    }

    figcaption {
      right: 0;
      top: 50%;
      transform: translateX(100%) rotate(-90deg) translateX(-50%);
      transform-origin: top left;
    }

    .backdrop {
      bottom: -6rem;
      height: 44rem;
      left: (121 / 382) * 100%;
    }
  }

  @include xl {
    .backdrop {
      left: (355 / 482) * 100%;
    }
  }
}

.image-text-full {
  position: relative;
  border-bottom: 3.2rem solid transparent;

  .backdrop {
    background: linear-gradient(to bottom, $gray-green 24rem, $gray-pink 24rem);
    height: 100%;
    top: 0;
    width: 100vw;
    left: calc(50% - 50vw);
  }

  .btn {
    bottom: 0;
    transform: translateY(50%);
  }

  @include xs {
    figure {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
    }

    figcaption {
      order: -1;
      transform: none;
      position: static;
      margin-bottom: 1.6rem;
    }

    .columns {
      margin-bottom: 8rem;
    }
  }

  @include md {
    .col-md-5 {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }

    .backdrop {
      background: linear-gradient(to right, $gray-green (1 / 3 * 100%), $gray-pink (1 / 3 * 100%));
      width: calc(100vw - 8rem);
      left: calc(50% - 50vw + 4rem);
    }

    figure {
      margin-right: 2rem;
    }

    img {
      height: 40rem;
    }

    figcaption {
      bottom: 0;
      transform: rotate(-90deg);
      transform-origin: bottom left;
    }
  }

  @include xl {
    figure {
      margin-right: 5.5rem;
    }
  }
}
