.project-samples {
  margin-top: 12rem;

  @include xs {
    img {
      height: 21.2rem;
    }

    .col-md {
      margin-bottom: 2.4rem;
    }

    .btn-wrap {
      margin-top: 2.4rem;
    }
  }

  @include md {
    h2 {
      margin-bottom: 8rem;
    }

    .btn-wrap {
      margin-top: 8rem;
    }
  }
}
