.pages-project {
  .hero h1 {
    @extend .h2;

    @include xxl {
      width: 67rem;
    }
  }
}

.project-details {
  .backdrop {
    right: 0;
    left: -50%;
    bottom: 0;
    height: calc(100% + 11rem);

    @include md {
      left: -7.5rem;
      height: calc(100% + 27rem);
    }

    @include xl {
      height: calc(100% + 30rem);
    }
  }
}

.project-summary {
  margin-top: 8rem;
}
