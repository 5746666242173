// Convert SVG pixel size attributes to rem.

svg {
  width: auto;

  * {
    vector-effect: non-scaling-stroke;
  }
}

@mixin svg-height($pixels) {
  svg[height="#{$pixels}"] {
    height: ($pixels * 0.1rem);
  }
}

@include svg-height(72);  // logo
@include svg-height(21);  // logo-text
@include svg-height(22);  // arrow-right, arrow-left
@include svg-height(24);  // navbar-toggler
@include svg-height(34);  // close
@include svg-height(38);  // slash
