footer {
  margin-top: 12rem;
  padding: 7.2rem 0 5.7rem;

  @include md {
    margin-top: 16rem;
    padding: 8rem 0;
  }

  @include xs {
    .logo svg {
      height: 5.4rem;
    }
  }

  .social-links {
    a {
      padding-bottom: 0.5rem;
      margin-bottom: -0.5rem;
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    svg {
      min-width: 1.6rem;
    }
  }
}

.copyright {
  color: $gray-400;

  @include xs {
    font-size: 1.2rem;
  }
}

.nav-link {
  padding: $nav-link-padding-y * 2 0 0;

  &:first-child {
    padding-top: 0;
  }
}
