.carousel-inner {
  position: relative;
}

.slides {
  @include transition(transform $carousel-t);
}

.slide {
  @include transition(opacity $carousel-t);

  position: relative;
  padding-left: 2rem;
  opacity: 0;

  &-first {
    position: absolute;
    right: 0;
  }

  &-active {
    opacity: 1;
  }
}

.slides-next {
  position: absolute;
  left: 100%;
  top: 0;
}

.carousel-projects {
  position: relative;
  margin-bottom: 5rem;

  @include md {
    position: absolute;
    width: 100%;
  }

  .carousel-inner,
  img {
    height: 63vw;

    @include md {
      height: 56rem;
    }
  }

  .carousel-inner {
    @include md {
      position: absolute;
      width: 54vw;
      right: 7.6rem;
    }

    @include xl {
      width: 58vw;
    }
  }

  .slide {
    &:not(.slide-active) {
      @include xs {
        opacity: 0.3;
      }
    }

    &-first {
      &,
      img {
        width: 100%;
      }
    }
  }

  .btn {
    @include transition(color $t, background $t, opacity $t);
  }

  figcaption {
    position: absolute;
    right: 0;
    top: 6.6rem;
    padding: 0;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: top right;

    @include xs {
      display: none;
    }
  }
}

.carousel-large {
  margin-top: 7.8rem;

  @include md {
    margin-top: 8.4rem;
  }

  .carousel-inner,
  img {
    height: 21.8rem;

    @include md {
      height: 54rem;
    }

    @include xl {
      height: 60rem;
    }
  }

  .carousel-inner {
    @include xl {
      max-width: 80%;
    }
  }

  .slide {
    opacity: 1;

    &::after {
      @include transition(opacity $carousel-t);

      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: $body-bg;
      opacity: 0.7;
      pointer-events: none;

      @include xs {
        left: -0.5px;
        top: -0.5px;
        right: -0.5px;
        bottom: -0.5px;
      }
    }

    &.slide-active::after {
      opacity: 0;
    }

    &-first {
      padding-left: 0;

      &,
      img {
        width: 100%;
      }
    }
  }
}

.carousel-small {
  margin-top: 12rem;

  .two-columns + & {
    margin-top: 4.8rem;

    &::before {
      content: none;
    }
  }

  .carousel-inner,
  img {
    height: 20rem;
    width: 100%;

    @include md {
      height: 50.6rem;
    }
  }

  .carousel-inner {
    max-width: 100%;

    @include md {
      max-width: 80%;
    }
  }

  .slide {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.carousel-controls {
  position: absolute;
  z-index: 1;
  top: -3.6em;
  bottom: -3.6em;
  font-size: 0.75rem;
  right: 0;
  pointer-events: none;

  .carousel-large & {
    @include xs {
      width: 11rem;
      top: -7.2em;
      bottom: -7.2em;
    }
  }

  @include md {
    width: 15em;
    font-size: 1rem;
    right: -3.6rem;

    .carousel-large & {
      right: 0;
    }
  }

  > * {
    pointer-events: auto;
    display: flex;
    width: 7.2em;
    height: 7.2em;
  }

  .btn {
    border: 0;

    svg {
      width: 50%;
      height: auto;
      margin: auto;
    }
  }
}

.btn-prev[disabled] {
  opacity: 0;

  .carousel-large & {
    opacity: 1;
  }
}

.counter {
  line-height: 2.6em;
  padding: 1em;
  background: $yellow;
  text-align: center;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  svg {
    position: absolute;
    height: 3.8em;
    top: 1.8em;
    left: 1.8em;
  }

  span {
    font-size: 1.6em;
    width: 1.5em;
  }
}

.counter-total {
  align-self: flex-end;
}

.carousel-footer {
  padding: 3.3rem 0 3.3rem 2.4rem;

  @include md {
    max-width: 58%;
  }

  .backdrop {
    background: $gray-pink;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4.8rem;
  }
}

.carousel-border img {
  border: $border-width solid $gray-pink;
}

$colors: (
  cream: $cream,
  gray-green: $gray-green,
  gray-pink: $gray-pink,
  bright-teal: $cyan
);

@each $color, $value in $colors {
  .two-columns-#{$color} + .carousel-small .backdrop {
    background: $value;
  }

  .two-columns-#{$color} + .carousel-border img {
    border-color: $value;
  }
}
