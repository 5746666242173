button:focus,
a:focus {
  outline: none;
}

.btn {
  color: $link-color !important;
  text-transform: uppercase;
  box-shadow: none !important;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border-color: currentColor !important;
    color: $link-hover-color !important;
  }

  &:disabled {
    cursor: default;
  }
}

.btn-light {
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: $gray-200;
  }
}

.btn-beige {
  background: $beige;
  border: 0;

  &:hover,
  &:focus,
  a:hover &,
  a:focus & {
    background: $yellow;
  }
}

.btn-dark {
  color: $white !important;
  border-color: transparent !important;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border-color: transparent !important;
    color: $yellow !important;
  }

  &:disabled {
    opacity: 1;
    background: $gray-200;
  }
}

.btn-link {
  border-width: 0 0 $border-width 0;
  padding: 0 0 0.6rem;

  &,
  &:hover,
  &:focus {
    background: 0;
  }
}

.btn-square {
  padding: 0;
  display: flex;
  width: 1em;
  min-width: 1em;
  height: 1em;
  font-size: 3.6rem;

  svg {
    margin: auto;
    width: 0.5em;
    height: 0.5em;
  }
}

[class*="btn-outline"] {
  &,
  &:hover,
  &:focus,
  &:active {
    background: 0 !important;
  }
}
