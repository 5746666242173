$gray-800: #343440;
$gray-100: #f7f7f7;
$gray-200: #e8e7e3;
$gray-400: rgba($gray-800, 0.25);
$gray-500: #acb7b8;
$gray-600: rgba($gray-800, 0.4);
$gray-700: #acb7b8;

$maroon: #ac5a50;
$red: #ee4622;
$red-brown: #d59c85;
$yellow: #ffef60;
$peach: #f9b58f;
$orange: #ddb167;
$peach-light: #f1d1b8;
$beige: #f0ebdd;
$gray-pink: #ece6e1;
$gray-green: #d9dfd3;
$green: #77c68f;
$green-light: #c5d8a7;
$cream: #e9eed8;
$cyan: #acf9d3;
$teal: #c1dfd5;
$blue: #8aa6b7;
$indigo: #2b40b7;

$primary: $indigo;
$secondary: $orange;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red-brown;
$light: $gray-100;
$dark: $gray-800;

$enable-rounded: false;
$enable-validation-icons: false;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * 0.5),
  2: $spacer,
  3: ($spacer * 2),
  4: ($spacer * 3),
  5: ($spacer * 4)
);

$body-color: $gray-800;
$link-color: $body-color;
$link-hover-color: $body-color;
$link-hover-decoration: none;

$paragraph-margin-bottom: (20 / 18) * 1em;

$grid-breakpoints: (
  xs: 0,
  md: 768px,  // Scaled "medium" breakpoint for tablet
  lg: 1000px, // Standard "medium" breakpoint for desktops
  xl: 1280px,
  xxl: 1580px,
);

$container-max-widths: (
  md: 617px,
  lg: 804px,
  xl: 1080px,
  xxl: 1284px
);

$grid-columns: 10;
$grid-gutter-width: 2rem;
$container-gutter-xs: 2.4rem;

$border-width: 2px;

$font-family-sans-serif: halyard-text, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-alt: henderson-sans-basic, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-base: 1.8rem;

$line-height-base: (30 / 18);

$h1-font-size: 8rem;
$h2-font-size: 8rem;
$h3-font-size: 4.4rem;
$h4-font-size: 2.4rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.6rem;
$headings-margin-bottom: 2.4rem;
$headings-font-family: $font-family-alt;
$headings-font-weight: 700;
$headings-line-height: 1.05;
$small-font-size: 1.4rem;
$hr-border-color: $gray-200;
$hr-margin-y: 12rem;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-border-radius: 0;

$form-group-margin-bottom: 3.2rem;

$input-btn-border-width: 0;
$btn-border-width: $border-width;
$btn-padding-x: 3rem;
$btn-padding-y-sm: 0;
$btn-padding-y: 1.8rem;
$btn-padding-y-sm: 0;
$btn-font-family: $font-family-alt;
$btn-font-size: 1.6rem;
$btn-font-size-sm: 1em;
$btn-line-height: 1.5;
$btn-font-weight: bold;
$btn-focus-box-shadow: inset 0 0 0 $border-width $gray-400;
$btn-white-space: nowrap;

$label-margin-bottom: 1.6rem;
$input-color: $body-color;
$input-padding-y: 2.1rem;
$input-padding-x: 2.4rem;
$input-line-height: (30 / 18);
$input-bg: $light;
$input-border-color: transparent;
$input-focus-border-color: transparent;
$input-placeholder-color: $gray-500;
$input-focus-box-shadow: 0 0 0 $border-width $gray-200;
$custom-select-focus-box-shadow: $input-focus-box-shadow;
$custom-select-bg-size: 2.6rem 1.4rem;
$custom-select-indicator-color: $body-color;
$custom-select-indicator: url("data:image/svg+xml,<svg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M25 1L13 13L0.999999 1' stroke='#{$custom-select-indicator-color}'/></svg>");
$form-feedback-valid-color: $body-color;
$form-feedback-font-size: inherit;

$nav-link-padding-y: 0.4rem;
$nav-link-padding-x: 0;

$thumbnail-padding: 0;
$thumbnail-bg: $gray-500;
$thumbnail-border-width: 0;
$thumbnail-box-shadow: none;
$figure-caption-font-size: 1.6rem;

$displays: none, inline-block, block, flex, inline-flex;
$positions: relative, absolute, sticky;

$t: 0.3s;
$overlay-t: 0.4s;
$carousel-t: 0.6s;
$navbar-height-xs: 5.8rem;
