@charset "UTF-8";
@import url("https://use.typekit.net/zle8lnz.css");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: halyard-text, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: #343440;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2.4rem; }

p {
  margin-top: 0;
  margin-bottom: 1.1111111111em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #343440;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #343440;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: rgba(52, 52, 64, 0.4);
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 1.6rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .pages-basic .hero h1, .pages-project .hero h1, .h3, .kirbytext h1, .h4, .kirbytext h2, label, .h5, .kirbytext h3, .h6 {
  margin-bottom: 2.4rem;
  font-family: henderson-sans-basic, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.05; }

h1, .h1 {
  font-size: 8rem; }

h2, .h2, .pages-basic .hero h1, .pages-project .hero h1 {
  font-size: 8rem; }

h3, .h3, .kirbytext h1 {
  font-size: 4.4rem; }

h4, .h4, .kirbytext h2, label {
  font-size: 2.4rem; }

h5, .h5, .kirbytext h3 {
  font-size: 1.8rem; }

h6, .h6 {
  font-size: 1.6rem; }

.lead {
  font-size: 2.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.05; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.05; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.05; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.05; }

hr {
  margin-top: 12rem;
  margin-bottom: 12rem;
  border: 0;
  border-top: 2px solid #e8e7e3; }

small,
.small {
  font-size: 1.4rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 2.25rem; }

.blockquote-footer {
  display: block;
  font-size: 1.4rem;
  color: rgba(52, 52, 64, 0.4); }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0;
  background-color: #acb7b8;
  border: 0 solid #dee2e6;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 1.6rem;
  color: rgba(52, 52, 64, 0.4); }

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 617px; } }
  @media (min-width: 1000px) {
    .container {
      max-width: 804px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1080px; } }
  @media (min-width: 1580px) {
    .container {
      max-width: 1284px; } }

.container-fluid, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container, .container-md {
    max-width: 617px; } }

@media (min-width: 1000px) {
  .container, .container-md, .container-lg {
    max-width: 804px; } }

@media (min-width: 1280px) {
  .container, .container-md, .container-lg, .container-xl {
    max-width: 1080px; } }

@media (min-width: 1580px) {
  .container, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1284px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col,
.col-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 10%;
  max-width: 10%; }

.col-2 {
  flex: 0 0 20%;
  max-width: 20%; }

.col-3 {
  flex: 0 0 30%;
  max-width: 30%; }

.col-4 {
  flex: 0 0 40%;
  max-width: 40%; }

.col-5 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-6 {
  flex: 0 0 60%;
  max-width: 60%; }

.col-7 {
  flex: 0 0 70%;
  max-width: 70%; }

.col-8 {
  flex: 0 0 80%;
  max-width: 80%; }

.col-9 {
  flex: 0 0 90%;
  max-width: 90%; }

.col-10 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 11; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.offset-1 {
  margin-left: 10%; }

.offset-2 {
  margin-left: 20%; }

.offset-3 {
  margin-left: 30%; }

.offset-4 {
  margin-left: 40%; }

.offset-5 {
  margin-left: 50%; }

.offset-6 {
  margin-left: 60%; }

.offset-7 {
  margin-left: 70%; }

.offset-8 {
  margin-left: 80%; }

.offset-9 {
  margin-left: 90%; }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-md-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-md-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-md-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-md-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-md-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-md-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-md-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-md-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 11; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 10%; }
  .offset-md-2 {
    margin-left: 20%; }
  .offset-md-3 {
    margin-left: 30%; }
  .offset-md-4 {
    margin-left: 40%; }
  .offset-md-5 {
    margin-left: 50%; }
  .offset-md-6 {
    margin-left: 60%; }
  .offset-md-7 {
    margin-left: 70%; }
  .offset-md-8 {
    margin-left: 80%; }
  .offset-md-9 {
    margin-left: 90%; } }

@media (min-width: 1000px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-lg-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-lg-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-lg-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-lg-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-lg-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-lg-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-lg-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-lg-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 11; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 10%; }
  .offset-lg-2 {
    margin-left: 20%; }
  .offset-lg-3 {
    margin-left: 30%; }
  .offset-lg-4 {
    margin-left: 40%; }
  .offset-lg-5 {
    margin-left: 50%; }
  .offset-lg-6 {
    margin-left: 60%; }
  .offset-lg-7 {
    margin-left: 70%; }
  .offset-lg-8 {
    margin-left: 80%; }
  .offset-lg-9 {
    margin-left: 90%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-xl-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-xl-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-xl-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-xl-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-xl-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-xl-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-xl-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-xl-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 11; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 10%; }
  .offset-xl-2 {
    margin-left: 20%; }
  .offset-xl-3 {
    margin-left: 30%; }
  .offset-xl-4 {
    margin-left: 40%; }
  .offset-xl-5 {
    margin-left: 50%; }
  .offset-xl-6 {
    margin-left: 60%; }
  .offset-xl-7 {
    margin-left: 70%; }
  .offset-xl-8 {
    margin-left: 80%; }
  .offset-xl-9 {
    margin-left: 90%; } }

@media (min-width: 1580px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 10%;
    max-width: 10%; }
  .col-xxl-2 {
    flex: 0 0 20%;
    max-width: 20%; }
  .col-xxl-3 {
    flex: 0 0 30%;
    max-width: 30%; }
  .col-xxl-4 {
    flex: 0 0 40%;
    max-width: 40%; }
  .col-xxl-5 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-6 {
    flex: 0 0 60%;
    max-width: 60%; }
  .col-xxl-7 {
    flex: 0 0 70%;
    max-width: 70%; }
  .col-xxl-8 {
    flex: 0 0 80%;
    max-width: 80%; }
  .col-xxl-9 {
    flex: 0 0 90%;
    max-width: 90%; }
  .col-xxl-10 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 11; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 10%; }
  .offset-xxl-2 {
    margin-left: 20%; }
  .offset-xxl-3 {
    margin-left: 30%; }
  .offset-xxl-4 {
    margin-left: 40%; }
  .offset-xxl-5 {
    margin-left: 50%; }
  .offset-xxl-6 {
    margin-left: 60%; }
  .offset-xxl-7 {
    margin-left: 70%; }
  .offset-xxl-8 {
    margin-left: 80%; }
  .offset-xxl-9 {
    margin-left: 90%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6666666667em + 4.2rem);
  padding: 2.1rem 2.4rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: #343440;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #343440; }
  .form-control:focus {
    color: #343440;
    background-color: #f7f7f7;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 2px #e8e7e3; }
  .form-control::placeholder {
    color: #acb7b8;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e8e7e3;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #343440;
  background-color: #f7f7f7; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: 2.1rem;
  padding-bottom: 2.1rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6666666667; }

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 2.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 1.575rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 2.1rem 0;
  margin-bottom: 0;
  font-size: 1.8rem;
  line-height: 1.6666666667;
  color: #343440;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 1.575rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 2.25rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 3.2rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: rgba(52, 52, 64, 0.4); }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: inherit;
  color: #343440; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.575rem;
  line-height: 1.6666666667;
  color: #fff;
  background-color: rgba(52, 52, 64, 0.9); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #343440; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #343440;
    box-shadow: 0 0 0 0.2rem rgba(52, 52, 64, 0.25); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #343440; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #343440;
    box-shadow: 0 0 0 0.2rem rgba(52, 52, 64, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #343440; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #343440; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #343440; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4b4b5c;
  background-color: #4b4b5c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(52, 52, 64, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #343440; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #343440; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #343440;
  box-shadow: 0 0 0 0.2rem rgba(52, 52, 64, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: inherit;
  color: #d59c85; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.575rem;
  line-height: 1.6666666667;
  color: #212529;
  background-color: rgba(213, 156, 133, 0.9); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d59c85; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d59c85;
    box-shadow: 0 0 0 0.2rem rgba(213, 156, 133, 0.25); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d59c85; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d59c85;
    box-shadow: 0 0 0 0.2rem rgba(213, 156, 133, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d59c85; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d59c85; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d59c85; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e2bbab;
  background-color: #e2bbab; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(213, 156, 133, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d59c85; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d59c85; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d59c85;
  box-shadow: 0 0 0 0.2rem rgba(213, 156, 133, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto; }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; }

.btn {
  display: inline-block;
  font-family: henderson-sans-basic, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  color: #343440;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 1.8rem 3rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #343440;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: inset 0 0 0 2px rgba(52, 52, 64, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2b40b7;
  border-color: #2b40b7; }
  .btn-primary:hover {
    color: #fff;
    background-color: #243598;
    border-color: #21328e; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #243598;
    border-color: #21328e;
    box-shadow: 0 0 0 0.2rem rgba(75, 93, 194, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #2b40b7;
    border-color: #2b40b7; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #21328e;
    border-color: #1f2e83; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 93, 194, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #ddb167;
  border-color: #ddb167; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #d6a148;
    border-color: #d49c3d; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #d6a148;
    border-color: #d49c3d;
    box-shadow: 0 0 0 0.2rem rgba(193, 156, 94, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #ddb167;
    border-color: #ddb167; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #d49c3d;
    border-color: #d19633; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(193, 156, 94, 0.5); }

.btn-success {
  color: #212529;
  background-color: #77c68f;
  border-color: #77c68f; }
  .btn-success:hover {
    color: #212529;
    background-color: #5cbb79;
    border-color: #53b771; }
  .btn-success:focus, .btn-success.focus {
    color: #212529;
    background-color: #5cbb79;
    border-color: #53b771;
    box-shadow: 0 0 0 0.2rem rgba(106, 174, 128, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #77c68f;
    border-color: #77c68f; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #53b771;
    border-color: #4bb26a; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(106, 174, 128, 0.5); }

.btn-info {
  color: #212529;
  background-color: #c1dfd5;
  border-color: #c1dfd5; }
  .btn-info:hover {
    color: #212529;
    background-color: #a8d2c4;
    border-color: #9fcebe; }
  .btn-info:focus, .btn-info.focus {
    color: #212529;
    background-color: #a8d2c4;
    border-color: #9fcebe;
    box-shadow: 0 0 0 0.2rem rgba(169, 195, 187, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #c1dfd5;
    border-color: #c1dfd5; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #212529;
    background-color: #9fcebe;
    border-color: #97c9b9; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(169, 195, 187, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffef60;
  border-color: #ffef60; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffeb3a;
    border-color: #ffea2d; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #ffeb3a;
    border-color: #ffea2d;
    box-shadow: 0 0 0 0.2rem rgba(222, 209, 88, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffef60;
    border-color: #ffef60; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffea2d;
    border-color: #ffe920; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 209, 88, 0.5); }

.btn-danger {
  color: #212529;
  background-color: #d59c85;
  border-color: #d59c85; }
  .btn-danger:hover {
    color: #212529;
    background-color: #cb8569;
    border-color: #c87d5f; }
  .btn-danger:focus, .btn-danger.focus {
    color: #212529;
    background-color: #cb8569;
    border-color: #c87d5f;
    box-shadow: 0 0 0 0.2rem rgba(186, 138, 119, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #212529;
    background-color: #d59c85;
    border-color: #d59c85; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c87d5f;
    border-color: #c57656; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(186, 138, 119, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f7f7f7;
  border-color: #f7f7f7; }
  .btn-light:hover {
    color: #212529;
    background-color: #e4e4e4;
    border-color: #dedddd; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e4e4e4;
    border-color: #dedddd;
    box-shadow: 0 0 0 0.2rem rgba(215, 216, 216, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dedddd;
    border-color: #d7d7d7; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 216, 216, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343440;
  border-color: #343440; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23232b;
    border-color: #1d1d24; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23232b;
    border-color: #1d1d24;
    box-shadow: 0 0 0 0.2rem rgba(82, 82, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343440;
    border-color: #343440; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d1d24;
    border-color: #17171d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 82, 93, 0.5); }

.btn-outline-primary {
  color: #2b40b7;
  border-color: #2b40b7; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2b40b7;
    border-color: #2b40b7; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 64, 183, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2b40b7;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2b40b7;
    border-color: #2b40b7; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 64, 183, 0.5); }

.btn-outline-secondary {
  color: #ddb167;
  border-color: #ddb167; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #ddb167;
    border-color: #ddb167; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 177, 103, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ddb167;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #ddb167;
    border-color: #ddb167; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 177, 103, 0.5); }

.btn-outline-success {
  color: #77c68f;
  border-color: #77c68f; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #77c68f;
    border-color: #77c68f; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 198, 143, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #77c68f;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #77c68f;
    border-color: #77c68f; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 198, 143, 0.5); }

.btn-outline-info {
  color: #c1dfd5;
  border-color: #c1dfd5; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #c1dfd5;
    border-color: #c1dfd5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(193, 223, 213, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #c1dfd5;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #c1dfd5;
    border-color: #c1dfd5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(193, 223, 213, 0.5); }

.btn-outline-warning {
  color: #ffef60;
  border-color: #ffef60; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffef60;
    border-color: #ffef60; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 239, 96, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffef60;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffef60;
    border-color: #ffef60; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 239, 96, 0.5); }

.btn-outline-danger {
  color: #d59c85;
  border-color: #d59c85; }
  .btn-outline-danger:hover {
    color: #212529;
    background-color: #d59c85;
    border-color: #d59c85; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 156, 133, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d59c85;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #d59c85;
    border-color: #d59c85; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 156, 133, 0.5); }

.btn-outline-light {
  color: #f7f7f7;
  border-color: #f7f7f7; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 247, 247, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f7f7f7;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f7f7f7;
    border-color: #f7f7f7; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 247, 247, 0.5); }

.btn-outline-dark {
  color: #343440;
  border-color: #343440; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343440;
    border-color: #343440; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 52, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343440;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343440;
    border-color: #343440; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 52, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #343440;
  text-decoration: none; }
  .btn-link:hover {
    color: #343440;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: rgba(52, 52, 64, 0.4);
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 2.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm {
  padding: 0 0.5rem;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 3rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 2rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2b40b7;
    background-color: #2b40b7; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 2px #e8e7e3; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: transparent; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #aab3eb;
    border-color: #aab3eb; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: rgba(52, 52, 64, 0.4); }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e8e7e3; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 1rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #f7f7f7;
    border: #acb7b8 solid 0; }
  .custom-control-label::after {
    position: absolute;
    top: 1rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2b40b7;
  background-color: #2b40b7; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(43, 64, 183, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(43, 64, 183, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(43, 64, 183, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: 1rem;
    left: -2.25rem;
    width: 1rem;
    height: 1rem;
    background-color: #acb7b8;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #f7f7f7;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(43, 64, 183, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6666666667em + 4.2rem);
  padding: 2.1rem 3.4rem 2.1rem 2.4rem;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: #343440;
  vertical-align: middle;
  background: #f7f7f7 url("data:image/svg+xml,%3csvg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M25 1L13 13L0.999999 1' stroke='%23343440'/%3e%3c/svg%3e") no-repeat right 2.4rem center/2.6rem 1.4rem;
  border: 0 solid transparent;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 2px #e8e7e3; }
    .custom-select:focus::-ms-value {
      color: #343440;
      background-color: #f7f7f7; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 2.4rem;
    background-image: none; }
  .custom-select:disabled {
    color: rgba(52, 52, 64, 0.4);
    background-color: #e8e7e3; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #343440; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.575rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6666666667em + 4.2rem);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6666666667em + 4.2rem);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: transparent;
    box-shadow: 0 0 0 2px #e8e7e3; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e8e7e3; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6666666667em + 4.2rem);
  padding: 2.1rem 2.4rem;
  font-weight: 400;
  line-height: 1.6666666667;
  color: #343440;
  background-color: #f7f7f7;
  border: 0 solid transparent; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6666666667em + 4.2rem);
    padding: 2.1rem 2.4rem;
    line-height: 1.6666666667;
    color: #343440;
    content: "Browse";
    background-color: #e8e7e3;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 2px #e8e7e3; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 2px #e8e7e3; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 2px #e8e7e3; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2b40b7;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #aab3eb; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2b40b7;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #aab3eb; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #2b40b7;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #aab3eb; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #acb7b8; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #acb7b8; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #acb7b8; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.4rem 0; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: rgba(52, 52, 64, 0.4);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 2px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    border: 2px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e8e7e3 #e8e7e3 #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: rgba(52, 52, 64, 0.4);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #acb7b8;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2b40b7; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.025rem;
  padding-bottom: 0.025rem;
  margin-right: 1rem;
  font-size: 2.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0 0;
  font-size: 2.25rem;
  line-height: 1;
  background-color: transparent;
  border: 2px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 999.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1000px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1279.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1280px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1579.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1580px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -2px;
  line-height: 1.25;
  color: #343440;
  background-color: #fff;
  border: 2px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #343440;
    text-decoration: none;
    background-color: #e8e7e3;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 64, 183, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2b40b7;
  border-color: #2b40b7; }

.page-item.disabled .page-link {
  color: rgba(52, 52, 64, 0.4);
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2.25rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.575rem;
  line-height: 1.5; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 5.2rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #16215f;
  background-color: #d5d9f1;
  border-color: #c4caeb; }
  .alert-primary hr {
    border-top-color: #b1b9e5; }
  .alert-primary .alert-link {
    color: #0c1336; }

.alert-secondary {
  color: #735c36;
  background-color: #f8efe1;
  border-color: #f5e9d4; }
  .alert-secondary hr {
    border-top-color: #f0debf; }
  .alert-secondary .alert-link {
    color: #504026; }

.alert-success {
  color: #3e674a;
  background-color: #e4f4e9;
  border-color: #d9efe0; }
  .alert-success hr {
    border-top-color: #c7e7d1; }
  .alert-success .alert-link {
    color: #2b4733; }

.alert-info {
  color: #64746f;
  background-color: #f3f9f7;
  border-color: #eef6f3; }
  .alert-info hr {
    border-top-color: #ddede7; }
  .alert-info .alert-link {
    color: #4c5955; }

.alert-warning {
  color: #857c32;
  background-color: #fffcdf;
  border-color: #fffbd2; }
  .alert-warning hr {
    border-top-color: #fff9b9; }
  .alert-warning .alert-link {
    color: #605924; }

.alert-danger {
  color: #6f5145;
  background-color: #f7ebe7;
  border-color: #f3e3dd; }
  .alert-danger hr {
    border-top-color: #ecd3ca; }
  .alert-danger .alert-link {
    color: #503a31; }

.alert-light {
  color: gray;
  background-color: #fdfdfd;
  border-color: #fdfdfd; }
  .alert-light hr {
    border-top-color: #f0f0f0; }
  .alert-light .alert-link {
    color: #676666; }

.alert-dark {
  color: #1b1b21;
  background-color: #d6d6d9;
  border-color: #c6c6ca; }
  .alert-dark hr {
    border-top-color: #b9b9be; }
  .alert-dark .alert-link {
    color: #040405; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border, form [type="submit"]::after {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2b40b7 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #21328e !important; }

.bg-secondary {
  background-color: #ddb167 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #d49c3d !important; }

.bg-success {
  background-color: #77c68f !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #53b771 !important; }

.bg-info {
  background-color: #c1dfd5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #9fcebe !important; }

.bg-warning {
  background-color: #ffef60 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffea2d !important; }

.bg-danger {
  background-color: #d59c85 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c87d5f !important; }

.bg-light {
  background-color: #f7f7f7 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dedddd !important; }

.bg-dark {
  background-color: #343440 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d1d24 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.d-none {
  display: none !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1000px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1580px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1000px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1580px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 3rem !important; }

.mt-4,
.my-4 {
  margin-top: 3rem !important; }

.mr-4,
.mx-4 {
  margin-right: 3rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 3rem !important; }

.m-5 {
  margin: 4rem !important; }

.mt-5,
.my-5 {
  margin-top: 4rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 4rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 3rem !important; }

.pt-4,
.py-4 {
  padding-top: 3rem !important; }

.pr-4,
.px-4 {
  padding-right: 3rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 3rem !important; }

.pl-4,
.px-4 {
  padding-left: 3rem !important; }

.p-5 {
  padding: 4rem !important; }

.pt-5,
.py-5 {
  padding-top: 4rem !important; }

.pr-5,
.px-5 {
  padding-right: 4rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4rem !important; }

.pl-5,
.px-5 {
  padding-left: 4rem !important; }

.m-n1 {
  margin: -0.5rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important; }

.m-n2 {
  margin: -1rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important; }

.m-n3 {
  margin: -2rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -2rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -2rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -2rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -2rem !important; }

.m-n4 {
  margin: -3rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -3rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -3rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -3rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -3rem !important; }

.m-n5 {
  margin: -4rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -4rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -4rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -4rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 3rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important; }
  .m-md-5 {
    margin: 4rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 3rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important; }
  .p-md-5 {
    padding: 4rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4rem !important; }
  .m-md-n1 {
    margin: -0.5rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important; }
  .m-md-n2 {
    margin: -1rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important; }
  .m-md-n3 {
    margin: -2rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -2rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -2rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -2rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -2rem !important; }
  .m-md-n4 {
    margin: -3rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -3rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -3rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -3rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -3rem !important; }
  .m-md-n5 {
    margin: -4rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1000px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 3rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important; }
  .m-lg-5 {
    margin: 4rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 3rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important; }
  .p-lg-5 {
    padding: 4rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4rem !important; }
  .m-lg-n1 {
    margin: -0.5rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important; }
  .m-lg-n2 {
    margin: -1rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important; }
  .m-lg-n3 {
    margin: -2rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -2rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -2rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -2rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -2rem !important; }
  .m-lg-n4 {
    margin: -3rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -3rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -3rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -3rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -3rem !important; }
  .m-lg-n5 {
    margin: -4rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1280px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 2rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important; }
  .m-xl-4 {
    margin: 3rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important; }
  .m-xl-5 {
    margin: 4rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 2rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important; }
  .p-xl-4 {
    padding: 3rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important; }
  .p-xl-5 {
    padding: 4rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4rem !important; }
  .m-xl-n1 {
    margin: -0.5rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important; }
  .m-xl-n2 {
    margin: -1rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important; }
  .m-xl-n3 {
    margin: -2rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -2rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -2rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -2rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -2rem !important; }
  .m-xl-n4 {
    margin: -3rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -3rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -3rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -3rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -3rem !important; }
  .m-xl-n5 {
    margin: -4rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1580px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.5rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.5rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.5rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.5rem !important; }
  .m-xxl-2 {
    margin: 1rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 1rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 1rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 1rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 1rem !important; }
  .m-xxl-3 {
    margin: 2rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 2rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 2rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 2rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 2rem !important; }
  .m-xxl-4 {
    margin: 3rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 3rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 3rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 3rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 3rem !important; }
  .m-xxl-5 {
    margin: 4rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 4rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 4rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 4rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 4rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.5rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.5rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.5rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.5rem !important; }
  .p-xxl-2 {
    padding: 1rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 1rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 1rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 1rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 1rem !important; }
  .p-xxl-3 {
    padding: 2rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 2rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 2rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 2rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 2rem !important; }
  .p-xxl-4 {
    padding: 3rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 3rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 3rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 3rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 3rem !important; }
  .p-xxl-5 {
    padding: 4rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 4rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 4rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 4rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 4rem !important; }
  .m-xxl-n1 {
    margin: -0.5rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.5rem !important; }
  .m-xxl-n2 {
    margin: -1rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -1rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -1rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -1rem !important; }
  .m-xxl-n3 {
    margin: -2rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -2rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -2rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -2rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -2rem !important; }
  .m-xxl-n4 {
    margin: -3rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -3rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -3rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -3rem !important; }
  .m-xxl-n5 {
    margin: -4rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -4rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -4rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -4rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -4rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1000px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1580px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2b40b7 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1c2a79 !important; }

.text-secondary {
  color: #ddb167 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #ca902d !important; }

.text-success {
  color: #77c68f !important; }

a.text-success:hover, a.text-success:focus {
  color: #47a965 !important; }

.text-info {
  color: #c1dfd5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #8fc5b3 !important; }

.text-warning {
  color: #ffef60 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffe714 !important; }

.text-danger {
  color: #d59c85 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #c16e4c !important; }

.text-light {
  color: #f7f7f7 !important; }

a.text-light:hover, a.text-light:focus {
  color: #d1d1d1 !important; }

.text-dark {
  color: #343440 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121216 !important; }

.text-body {
  color: #343440 !important; }

.text-muted {
  color: rgba(52, 52, 64, 0.4) !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 2px solid #acb7b8;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1000px !important; }
  .container {
    min-width: 1000px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 2px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased; }
  @media (min-width: 768px) and (max-width: 999px) {
    html {
      font-size: 7.68px; } }
  @media (min-width: 1900px) {
    html {
      font-size: 12.0253164557px; } }

.wrap {
  overflow-x: hidden; }

@media (max-width: 767.98px) {
  .container {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
    .container .container {
      padding-left: 1rem;
      padding-right: 1rem; } }

@media (min-width: 1900px) {
  .container {
    max-width: 1544px; } }

.hug {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw); }

@media (max-width: 767.98px) {
  .hug-xs {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw); } }

@media (min-width: 768px) {
  .hug-md {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw); } }

svg {
  width: auto; }
  svg * {
    vector-effect: non-scaling-stroke; }

svg[height="72"] {
  height: 7.2rem; }

svg[height="21"] {
  height: 2.1rem; }

svg[height="22"] {
  height: 2.2rem; }

svg[height="24"] {
  height: 2.4rem; }

svg[height="34"] {
  height: 3.4rem; }

svg[height="38"] {
  height: 3.8rem; }

@media (max-width: 1279.98px) {
  body {
    font-size: 1.6rem; } }

a {
  transition: color 0.3s, background 0.3s, border 0.3s; }
  @media (prefers-reduced-motion: reduce) {
    a {
      transition: none; } }
  a:hover, a:focus {
    color: #343440 !important; }

.nav-link,
a:not([class]) {
  border-bottom: 1px solid transparent; }
  .nav-link:hover, .nav-link:focus,
  a:not([class]):hover,
  a:not([class]):focus {
    border-bottom-color: #343440; }

h1,
.h1,
h2, .h2, .pages-basic .hero h1, .pages-project .hero h1,
h3, .h3, .kirbytext h1,
h4, .h4, .kirbytext h2, label,
h5, .h5, .kirbytext h3,
h6,
.h6 {
  letter-spacing: -0.05em; }

@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 4.4rem;
    line-height: 1.2727272727; } }

@media (min-width: 768px) {
  h1, .h1 {
    margin-bottom: 4rem; } }

@media (min-width: 1280px) {
  h1, .h1 {
    font-size: 10rem; } }

@media (max-width: 767.98px) {
  h2, .h2, .pages-basic .hero h1, .pages-project .hero h1 {
    font-size: 4.4rem;
    line-height: 1.2727272727; } }

h3, .h3, .kirbytext h1 {
  line-height: 1.2727272727; }
  @media (min-width: 768px) {
    h3, .h3, .kirbytext h1 {
      margin-bottom: 3.6rem; } }

h4, .h4, .kirbytext h2, label,
h5, .h5, .kirbytext h3,
h6,
.h6 {
  line-height: 1.5; }

h5, .h5, .kirbytext h3 {
  margin-bottom: 1.6rem; }

h6, .h6 {
  margin-bottom: 1.2rem; }
  h6.text-uppercase,
  .h6.text-uppercase {
    letter-spacing: 0.04em; }

.kirbytext h1:not(:first-child) {
  margin-top: 4.8rem; }

.kirbytext h2:not(:first-child) {
  margin-top: 4.8rem; }

.kirbytext h3:not(:first-child) {
  margin-top: 4rem; }

small,
.small {
  line-height: 1.4285714286; }

.smaller {
  font-size: 1.6rem; }

ul,
ol {
  margin: 0 0 1.1111111111em;
  padding: 0; }

li {
  margin-bottom: 0.75em;
  padding-left: 1.25em;
  position: relative; }

ul {
  list-style: none; }
  ul li::before {
    content: "•";
    color: #d59c85;
    position: absolute;
    left: 0; }

p:last-child,
ul:last-child,
ol:last-child {
  margin-bottom: 0; }

img {
  max-width: 100%; }

figure {
  position: relative;
  margin: 0; }

figcaption {
  font-family: henderson-sans-basic, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  letter-spacing: 0.2em;
  line-height: 1.5;
  padding: 1.8rem 0;
  text-transform: uppercase;
  white-space: nowrap; }
  @media (min-width: 1280px) {
    figcaption {
      padding: 2.8rem 0; } }
  @media (max-width: 767.98px) {
    figcaption {
      font-size: 1.2rem;
      padding: 3rem 0; } }

.btn-wrap {
  margin-top: 2.4rem; }

.img-cover {
  object-fit: cover;
  object-position: 50% 50%; }

.img-contain {
  object-fit: contain;
  object-position: 50% 50%; }

.backdrop {
  position: absolute;
  z-index: -1; }

@media (min-width: 768px) {
  .columns {
    column-gap: 2rem;
    columns: 2; }
    .columns p,
    .columns ul {
      break-inside: avoid; } }

@media (min-width: 1280px) {
  .columns {
    column-gap: 4rem; } }

@media (min-width: 768px) {
  hr, .hr-before::before, .hr-after + *::before {
    content: "";
    display: block;
    width: 12rem;
    height: 1px;
    background: #e8e7e3;
    margin: 12rem auto; } }

.hr-before:first-child::before {
  content: none; }

.social-link {
  display: flex;
  width: 1em;
  height: 1em;
  font-size: 5.4rem; }
  .social-link:hover, .social-link:focus {
    background: #fff; }
  .social-link svg {
    margin: auto;
    width: auto;
    height: 0.3333333333em; }

.square {
  padding-top: 100%; }

.sideways-md {
  cursor: vertical-text; }

.img-bounds {
  overflow: hidden; }
  .img-bounds img {
    transition: transform 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .img-bounds img {
        transition: none; } }
    a:hover .img-bounds img,
    a:focus .img-bounds img {
      transform: scale(1.05); }

button:focus,
a:focus {
  outline: none; }

.btn {
  color: #343440 !important;
  text-transform: uppercase;
  box-shadow: none !important; }
  .btn:not(:disabled):hover, .btn:not(:disabled):focus {
    border-color: currentColor !important;
    color: #343440 !important; }
  .btn:disabled {
    cursor: default; }

.btn-light:not(:disabled):hover, .btn-light:not(:disabled):focus {
  background: #e8e7e3; }

.btn-beige {
  background: #f0ebdd;
  border: 0; }
  .btn-beige:hover, .btn-beige:focus,
  a:hover .btn-beige,
  a:focus .btn-beige {
    background: #ffef60; }

.btn-dark {
  color: #fff !important;
  border-color: transparent !important; }
  .btn-dark:not(:disabled):hover, .btn-dark:not(:disabled):focus {
    border-color: transparent !important;
    color: #ffef60 !important; }
  .btn-dark:disabled {
    opacity: 1;
    background: #e8e7e3; }

.btn-link {
  border-width: 0 0 2px 0;
  padding: 0 0 0.6rem; }
  .btn-link, .btn-link:hover, .btn-link:focus {
    background: 0; }

.btn-square {
  padding: 0;
  display: flex;
  width: 1em;
  min-width: 1em;
  height: 1em;
  font-size: 3.6rem; }
  .btn-square svg {
    margin: auto;
    width: 0.5em;
    height: 0.5em; }

[class*="btn-outline"], [class*="btn-outline"]:hover, [class*="btn-outline"]:focus, [class*="btn-outline"]:active {
  background: 0 !important; }

.form-control,
.custom-select {
  border-bottom-width: 2px; }

label {
  margin-bottom: 1.6rem !important; }

select:invalid {
  color: #acb7b8; }

.page-contact main::before {
  content: none; }

form .btn-wrap {
  margin-top: 6rem; }

form [type="submit"] {
  position: relative; }
  form [type="submit"]::before, form [type="submit"]::after {
    transition: opacity 0.3s;
    content: "";
    pointer-events: none;
    opacity: 0;
    position: absolute; }
    @media (prefers-reduced-motion: reduce) {
      form [type="submit"]::before, form [type="submit"]::after {
        transition: none; } }
  form [type="submit"]::before {
    z-index: 1;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    background: rgba(255, 255, 255, 0.7); }
  form [type="submit"]::after {
    color: #ddb167;
    z-index: 2;
    left: calc(50% - 2rem);
    top: calc(50% - 2rem);
    width: 4rem;
    height: 4rem; }

.form-busy [type="submit"]::before, .form-busy [type="submit"]::after {
  opacity: 1;
  pointer-events: auto; }

.msg {
  display: none;
  margin-top: 6rem; }

.form-success .msg-success {
  display: block; }

.form-error .msg-error {
  display: block; }

.contact-form {
  margin-top: 12rem;
  padding: 6rem 0; }
  @media (min-width: 768px) {
    .contact-form {
      padding: 8rem 0; }
      .contact-form:first-child {
        margin-top: 0; } }
  .contact-form .backdrop {
    left: -50%;
    top: 0;
    bottom: 0;
    right: 90%;
    background: linear-gradient(to bottom, #f0ebdd 77%, #d9dfd3 77%); }
  .contact-form figcaption {
    position: absolute;
    top: 9.2rem;
    left: calc(50% - 50vw);
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100%); }
  .contact-form h2 {
    margin-bottom: 6rem; }
    @media (min-width: 768px) {
      .contact-form h2 {
        margin-bottom: 10rem; } }
  .contact-form .invalid-feedback {
    width: auto; }
  .contact-form .was-validated .invalid-feedback {
    display: block; }

.text-maroon {
  color: #ac5a50; }

.bg-maroon {
  background-color: #ac5a50; }

.border-maroon {
  border-color: #ac5a50; }

.text-red {
  color: #ee4622; }

.bg-red {
  background-color: #ee4622; }

.border-red {
  border-color: #ee4622; }

.text-red-brown {
  color: #d59c85; }

.bg-red-brown {
  background-color: #d59c85; }

.border-red-brown {
  border-color: #d59c85; }

.text-yellow {
  color: #ffef60; }

.bg-yellow {
  background-color: #ffef60; }

.border-yellow {
  border-color: #ffef60; }

.text-peach {
  color: #f9b58f; }

.bg-peach {
  background-color: #f9b58f; }

.border-peach {
  border-color: #f9b58f; }

.text-peach-light {
  color: #f1d1b8; }

.bg-peach-light {
  background-color: #f1d1b8; }

.border-peach-light {
  border-color: #f1d1b8; }

.text-gold {
  color: #ddb167; }

.bg-gold {
  background-color: #ddb167; }

.border-gold {
  border-color: #ddb167; }

.text-beige {
  color: #f0ebdd; }

.bg-beige {
  background-color: #f0ebdd; }

.border-beige {
  border-color: #f0ebdd; }

.text-gray-pink {
  color: #ece6e1; }

.bg-gray-pink {
  background-color: #ece6e1; }

.border-gray-pink {
  border-color: #ece6e1; }

.text-gray-green {
  color: #d9dfd3; }

.bg-gray-green {
  background-color: #d9dfd3; }

.border-gray-green {
  border-color: #d9dfd3; }

.text-gray-warm {
  color: #e8e7e3; }

.bg-gray-warm {
  background-color: #e8e7e3; }

.border-gray-warm {
  border-color: #e8e7e3; }

.text-gray-dark {
  color: #acb7b8; }

.bg-gray-dark {
  background-color: #acb7b8; }

.border-gray-dark {
  border-color: #acb7b8; }

.text-green {
  color: #77c68f; }

.bg-green {
  background-color: #77c68f; }

.border-green {
  border-color: #77c68f; }

.text-green-light {
  color: #c5d8a7; }

.bg-green-light {
  background-color: #c5d8a7; }

.border-green-light {
  border-color: #c5d8a7; }

.text-cream {
  color: #e9eed8; }

.bg-cream {
  background-color: #e9eed8; }

.border-cream {
  border-color: #e9eed8; }

.text-bright-teal {
  color: #acf9d3; }

.bg-bright-teal {
  background-color: #acf9d3; }

.border-bright-teal {
  border-color: #acf9d3; }

.text-teal {
  color: #c1dfd5; }

.bg-teal {
  background-color: #c1dfd5; }

.border-teal {
  border-color: #c1dfd5; }

.text-blue {
  color: #8aa6b7; }

.bg-blue {
  background-color: #8aa6b7; }

.border-blue {
  border-color: #8aa6b7; }

.text-indigo {
  color: #2b40b7; }

.bg-indigo {
  background-color: #2b40b7; }

.border-indigo {
  border-color: #2b40b7; }

.nav-header {
  margin-bottom: 4.8rem;
  pointer-events: none; }
  .nav-header > * {
    pointer-events: auto; }
  @media (min-width: 768px) {
    .nav-header {
      margin-bottom: 10rem; } }
  .pages-basic .nav-header {
    margin-bottom: 6.8rem; }
    @media (min-width: 768px) {
      .pages-basic .nav-header {
        margin-bottom: 16rem; } }

.navbar-brand {
  background: #f7f7f7;
  padding: 4rem;
  border: 0 !important; }
  @media (max-width: 767.98px) {
    .navbar-brand {
      padding: 2.1rem 2.4rem; }
      .navbar-brand svg {
        height: 1.65rem; } }
  .navbar-brand,
  .navbar-brand h1 {
    line-height: 1px; }

.navbar-toggler {
  transition: background 0.3s;
  border: 0;
  display: flex;
  width: 7.2rem;
  height: 7.2rem; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  @media (max-width: 767.98px) {
    .navbar-toggler {
      width: 5rem;
      height: 5rem; } }
  .navbar-toggler svg {
    transition: transform 0.3s;
    margin: auto; }
    @media (prefers-reduced-motion: reduce) {
      .navbar-toggler svg {
        transition: none; } }

.navbar-hamburger:hover, .navbar-hamburger:focus {
  background: #f7f7f7; }
  .navbar-hamburger:hover svg, .navbar-hamburger:focus svg {
    transform: scaleY(1.4); }

@media (max-width: 767.98px) {
  .navbar-hamburger svg {
    height: 1.8rem; } }

.navbar-close:hover, .navbar-close:focus {
  background: #fff; }
  .navbar-close:hover svg, .navbar-close:focus svg {
    transform: scale(1.2); }

@media (max-width: 767.98px) {
  .navbar-close svg {
    height: 2.4rem; } }

.breadcrumbs {
  margin: 6.8rem 0 3.2rem; }
  @media (min-width: 768px) {
    .breadcrumbs {
      position: relative;
      margin: 0 0 2rem;
      top: -4.2rem; } }

.overlay {
  transition: opacity 0.16s 0.24s, background-position 0.4s;
  pointer-events: none;
  opacity: 0;
  background: -200% 0/200% 100% linear-gradient(to right, rgba(247, 247, 247, 0) 47%, #f7f7f7 50%); }
  @media (prefers-reduced-motion: reduce) {
    .overlay {
      transition: none; } }
  .overlay > * {
    transition: opacity 0.2s !important;
    opacity: 0; }
  .overlay-shown .overlay {
    transition: opacity 0.16s, background-position 0.4s;
    pointer-events: auto;
    opacity: 1;
    background-position: -100% 0; }
    @media (prefers-reduced-motion: reduce) {
      .overlay-shown .overlay {
        transition: none; } }
    .overlay-shown .overlay > * {
      transition: opacity 0.4s 0.2s !important;
      opacity: 1; }
  .overlay .menu-link {
    position: relative;
    line-height: 1.03; }
    .overlay .menu-link::after {
      transition: opacity 0.3s, bottom 0.3s;
      content: "";
      position: absolute;
      z-index: -1;
      opacity: 0;
      bottom: 0;
      left: 0;
      right: -0.25em;
      height: 0.2em;
      background: currentColor; }
      @media (prefers-reduced-motion: reduce) {
        .overlay .menu-link::after {
          transition: none; } }
    .overlay .menu-link:hover::after, .overlay .menu-link:focus::after {
      opacity: 1;
      bottom: 0.125em; }
    @media (max-width: 767.98px) {
      .overlay .menu-link {
        font-size: 5.2rem; }
        .overlay .menu-link::after {
          right: -0.1em; } }
  .overlay .navbar-brand {
    background: #fff; }
  @media (min-width: 768px) {
    .overlay .social-links {
      position: absolute;
      right: 4rem; }
    .overlay .social-link {
      font-size: 7.2rem; } }
  .overlay :last-child .menu-link {
    margin-bottom: 0; }
  .overlay .container {
    padding-bottom: 7vh; }
    @media (min-width: 768px) {
      .overlay .container {
        padding-bottom: 5rem;
        height: 100%; } }
    @media (max-width: 767.98px) {
      .overlay .container {
        position: absolute;
        top: 5.8rem;
        bottom: 0; } }

@media (max-width: 767.98px) {
  .navbar-height-xs {
    min-height: 5.8rem; } }

.nav-beige::after {
  color: #f0ebdd; }

.nav-teal::after {
  color: #c1dfd5; }

.nav-cream::after {
  color: #e9eed8; }

.nav-peach::after {
  color: #f9b58f; }

footer {
  margin-top: 12rem;
  padding: 7.2rem 0 5.7rem; }
  @media (min-width: 768px) {
    footer {
      margin-top: 16rem;
      padding: 8rem 0; } }
  @media (max-width: 767.98px) {
    footer .logo svg {
      height: 5.4rem; } }
  footer .social-links a {
    padding-bottom: 0.5rem;
    margin-bottom: -0.5rem;
    margin-right: 2rem; }
    footer .social-links a:last-child {
      margin-right: 0; }
  footer .social-links svg {
    min-width: 1.6rem; }

.copyright {
  color: rgba(52, 52, 64, 0.25); }
  @media (max-width: 767.98px) {
    .copyright {
      font-size: 1.2rem; } }

.nav-link {
  padding: 0.8rem 0 0; }
  .nav-link:first-child {
    padding-top: 0; }

@media (min-width: 768px) {
  .hero h1 {
    width: 55rem; } }

@media (min-width: 1280px) {
  .hero h1 {
    width: 67rem; } }

@media (min-width: 1580px) {
  .hero h1 {
    width: 75rem; } }

.pages-basic .hero h1 {
  margin-bottom: 8rem; }
  @media (min-width: 768px) {
    .pages-basic .hero h1 {
      margin-bottom: 9rem; } }

@media (min-width: 768px) {
  .hero .body {
    width: 28rem; } }

@media (min-width: 1280px) {
  .hero .body {
    width: 44rem; } }

@media (min-width: 1580px) {
  .hero .body {
    width: 47.5rem; } }

.hero-carousel .body {
  width: 80%; }
  @media (min-width: 768px) {
    .hero-carousel .body {
      width: 20.5rem; } }
  @media (min-width: 1280px) {
    .hero-carousel .body {
      width: 32.5rem; } }
  @media (min-width: 1580px) {
    .hero-carousel .body {
      width: 35rem; } }

@media (min-width: 768px) {
  .hero-image,
  .hero-carousel {
    position: relative;
    padding-top: 6rem; }
    .hero-image > figure,
    .hero-image > .carousel,
    .hero-carousel > figure,
    .hero-carousel > .carousel {
      top: 0; } }

@media (min-width: 768px) {
  .hero-image .container {
    min-height: 76rem; } }

.hero-image figure {
  margin: 0 2.4rem; }
  @media (min-width: 768px) {
    .hero-image figure {
      position: absolute;
      margin: 0;
      right: 4rem; } }
  @media (min-width: 1280px) {
    .hero-image figure {
      right: 7.6rem; } }

.hero-image img {
  height: 37.2rem;
  width: 100%; }
  @media (min-width: 768px) {
    .hero-image img {
      height: 72rem;
      max-width: 51vw; } }
  @media (min-width: 1280px) {
    .hero-image img {
      max-width: 44vw; } }

@media (min-width: 768px) {
  .hero-image figcaption {
    position: absolute;
    transform: rotate(-90deg) translate(50%, -100%);
    transform-origin: top right;
    right: 0; } }

.hero-image .backdrop {
  width: 92%;
  height: 32rem;
  top: 9.2rem;
  right: -4rem; }
  @media (min-width: 768px) {
    .hero-image .backdrop {
      width: 88%;
      height: 85%;
      top: auto;
      bottom: -10rem; } }
  @media (min-width: 1280px) {
    .hero-image .backdrop {
      right: -3.6rem; } }

@media (min-width: 768px) {
  .hero-carousel .container {
    min-height: 50rem; } }

.carousel-inner {
  position: relative; }

.slides {
  transition: transform 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .slides {
      transition: none; } }

.slide {
  transition: opacity 0.6s;
  position: relative;
  padding-left: 2rem;
  opacity: 0; }
  @media (prefers-reduced-motion: reduce) {
    .slide {
      transition: none; } }
  .slide-first {
    position: absolute;
    right: 0; }
  .slide-active {
    opacity: 1; }

.slides-next {
  position: absolute;
  left: 100%;
  top: 0; }

.carousel-projects {
  position: relative;
  margin-bottom: 5rem; }
  @media (min-width: 768px) {
    .carousel-projects {
      position: absolute;
      width: 100%; } }
  .carousel-projects .carousel-inner,
  .carousel-projects img {
    height: 63vw; }
    @media (min-width: 768px) {
      .carousel-projects .carousel-inner,
      .carousel-projects img {
        height: 56rem; } }
  @media (min-width: 768px) {
    .carousel-projects .carousel-inner {
      position: absolute;
      width: 54vw;
      right: 7.6rem; } }
  @media (min-width: 1280px) {
    .carousel-projects .carousel-inner {
      width: 58vw; } }
  @media (max-width: 767.98px) {
    .carousel-projects .slide:not(.slide-active) {
      opacity: 0.3; } }
  .carousel-projects .slide-first,
  .carousel-projects .slide-first img {
    width: 100%; }
  .carousel-projects .btn {
    transition: color 0.3s, background 0.3s, opacity 0.3s; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-projects .btn {
        transition: none; } }
  .carousel-projects figcaption {
    position: absolute;
    right: 0;
    top: 6.6rem;
    padding: 0;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: top right; }
    @media (max-width: 767.98px) {
      .carousel-projects figcaption {
        display: none; } }

.carousel-large {
  margin-top: 7.8rem; }
  @media (min-width: 768px) {
    .carousel-large {
      margin-top: 8.4rem; } }
  .carousel-large .carousel-inner,
  .carousel-large img {
    height: 21.8rem; }
    @media (min-width: 768px) {
      .carousel-large .carousel-inner,
      .carousel-large img {
        height: 54rem; } }
    @media (min-width: 1280px) {
      .carousel-large .carousel-inner,
      .carousel-large img {
        height: 60rem; } }
  @media (min-width: 1280px) {
    .carousel-large .carousel-inner {
      max-width: 80%; } }
  .carousel-large .slide {
    opacity: 1; }
    .carousel-large .slide::after {
      transition: opacity 0.6s;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      opacity: 0.7;
      pointer-events: none; }
      @media (prefers-reduced-motion: reduce) {
        .carousel-large .slide::after {
          transition: none; } }
      @media (max-width: 767.98px) {
        .carousel-large .slide::after {
          left: -0.5px;
          top: -0.5px;
          right: -0.5px;
          bottom: -0.5px; } }
    .carousel-large .slide.slide-active::after {
      opacity: 0; }
    .carousel-large .slide-first {
      padding-left: 0; }
      .carousel-large .slide-first,
      .carousel-large .slide-first img {
        width: 100%; }

.carousel-small {
  margin-top: 12rem; }
  .two-columns + .carousel-small {
    margin-top: 4.8rem; }
    .two-columns + .carousel-small::before {
      content: none; }
  .carousel-small .carousel-inner,
  .carousel-small img {
    height: 20rem;
    width: 100%; }
    @media (min-width: 768px) {
      .carousel-small .carousel-inner,
      .carousel-small img {
        height: 50.6rem; } }
  .carousel-small .carousel-inner {
    max-width: 100%; }
    @media (min-width: 768px) {
      .carousel-small .carousel-inner {
        max-width: 80%; } }
  .carousel-small .slide {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }

.carousel-controls {
  position: absolute;
  z-index: 1;
  top: -3.6em;
  bottom: -3.6em;
  font-size: 0.75rem;
  right: 0;
  pointer-events: none; }
  @media (max-width: 767.98px) {
    .carousel-large .carousel-controls {
      width: 11rem;
      top: -7.2em;
      bottom: -7.2em; } }
  @media (min-width: 768px) {
    .carousel-controls {
      width: 15em;
      font-size: 1rem;
      right: -3.6rem; }
      .carousel-large .carousel-controls {
        right: 0; } }
  .carousel-controls > * {
    pointer-events: auto;
    display: flex;
    width: 7.2em;
    height: 7.2em; }
  .carousel-controls .btn {
    border: 0; }
    .carousel-controls .btn svg {
      width: 50%;
      height: auto;
      margin: auto; }

.btn-prev[disabled] {
  opacity: 0; }
  .carousel-large .btn-prev[disabled] {
    opacity: 1; }

.counter {
  line-height: 2.6em;
  padding: 1em;
  background: #ffef60;
  text-align: center;
  position: relative;
  flex-direction: column;
  justify-content: space-between; }
  .counter svg {
    position: absolute;
    height: 3.8em;
    top: 1.8em;
    left: 1.8em; }
  .counter span {
    font-size: 1.6em;
    width: 1.5em; }

.counter-total {
  align-self: flex-end; }

.carousel-footer {
  padding: 3.3rem 0 3.3rem 2.4rem; }
  @media (min-width: 768px) {
    .carousel-footer {
      max-width: 58%; } }
  .carousel-footer .backdrop {
    background: #ece6e1;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4.8rem; }

.carousel-border img {
  border: 2px solid #ece6e1; }

.two-columns-cream + .carousel-small .backdrop {
  background: #e9eed8; }

.two-columns-cream + .carousel-border img {
  border-color: #e9eed8; }

.two-columns-gray-green + .carousel-small .backdrop {
  background: #d9dfd3; }

.two-columns-gray-green + .carousel-border img {
  border-color: #d9dfd3; }

.two-columns-gray-pink + .carousel-small .backdrop {
  background: #ece6e1; }

.two-columns-gray-pink + .carousel-border img {
  border-color: #ece6e1; }

.two-columns-bright-teal + .carousel-small .backdrop {
  background: #acf9d3; }

.two-columns-bright-teal + .carousel-border img {
  border-color: #acf9d3; }

.image-text {
  margin-top: 12rem; }
  .image-text figcaption {
    position: absolute; }
  @media (max-width: 767.98px) {
    .image-text figure {
      margin-bottom: 8rem; }
    .image-text figcaption {
      margin-top: 4rem;
      padding: 0;
      transform: translateY(-50%); } }
  @media (min-width: 768px) {
    .image-text + .image-text {
      margin-top: 16rem; } }

.image-text-left img {
  height: 30.3rem; }

.image-text-left .backdrop {
  bottom: -4rem;
  height: 28rem;
  left: -14.6788990826%;
  width: 100%; }

@media (min-width: 768px) {
  .image-text-left figure {
    left: -4rem;
    margin-bottom: 6rem; }
  .image-text-left img {
    height: 44rem; }
  .image-text-left figcaption {
    bottom: 0;
    transform: rotate(-90deg);
    transform-origin: bottom left; }
  .image-text-left .backdrop {
    bottom: -6rem;
    height: 38rem;
    left: -20.8487084871%; } }

.image-text-right img {
  height: 41.2rem; }

.image-text-right figcaption {
  right: 0; }

.image-text-right .backdrop {
  bottom: -4rem;
  height: 36rem;
  left: 63.3027522936%;
  width: 100%; }

@media (min-width: 768px) {
  .image-text-right figure {
    left: 7rem;
    margin-bottom: 6rem; }
  .image-text-right img {
    height: 52rem; }
  .image-text-right figcaption {
    right: 0;
    top: 50%;
    transform: translateX(100%) rotate(-90deg) translateX(-50%);
    transform-origin: top left; }
  .image-text-right .backdrop {
    bottom: -6rem;
    height: 44rem;
    left: 31.6753926702%; } }

@media (min-width: 1280px) {
  .image-text-right .backdrop {
    left: 73.6514522822%; } }

.image-text-full {
  position: relative;
  border-bottom: 3.2rem solid transparent; }
  .image-text-full .backdrop {
    background: linear-gradient(to bottom, #d9dfd3 24rem, #ece6e1 24rem);
    height: 100%;
    top: 0;
    width: 100vw;
    left: calc(50% - 50vw); }
  .image-text-full .btn {
    bottom: 0;
    transform: translateY(50%); }
  @media (max-width: 767.98px) {
    .image-text-full figure {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem; }
    .image-text-full figcaption {
      order: -1;
      transform: none;
      position: static;
      margin-bottom: 1.6rem; }
    .image-text-full .columns {
      margin-bottom: 8rem; } }
  @media (min-width: 768px) {
    .image-text-full .col-md-5 {
      padding-top: 10rem;
      padding-bottom: 10rem; }
    .image-text-full .backdrop {
      background: linear-gradient(to right, #d9dfd3 33.3333333333%, #ece6e1 33.3333333333%);
      width: calc(100vw - 8rem);
      left: calc(50% - 50vw + 4rem); }
    .image-text-full figure {
      margin-right: 2rem; }
    .image-text-full img {
      height: 40rem; }
    .image-text-full figcaption {
      bottom: 0;
      transform: rotate(-90deg);
      transform-origin: bottom left; } }
  @media (min-width: 1280px) {
    .image-text-full figure {
      margin-right: 5.5rem; } }

.project-samples {
  margin-top: 12rem; }
  @media (max-width: 767.98px) {
    .project-samples img {
      height: 21.2rem; }
    .project-samples .col-md {
      margin-bottom: 2.4rem; }
    .project-samples .btn-wrap {
      margin-top: 2.4rem; } }
  @media (min-width: 768px) {
    .project-samples h2 {
      margin-bottom: 8rem; }
    .project-samples .btn-wrap {
      margin-top: 8rem; } }

.three-columns {
  margin-top: 12rem; }
  @media (min-width: 768px) {
    .three-columns .col-md-3:nth-of-type(2) {
      padding-top: 12rem; }
    .three-columns .col-md-3:last-of-type {
      padding-top: 24rem; } }
  @media (max-width: 767.98px) {
    .three-columns .col-md-3 + .col-md-3 {
      margin-top: 7.2rem; } }
  @media (min-width: 768px) {
    .three-columns figure {
      margin-top: 6.3rem;
      margin-left: -20%;
      margin-right: -2rem;
      margin-bottom: 10rem; } }
  .three-columns img {
    height: 28.2rem;
    width: 100%; }
    @media (min-width: 768px) {
      .three-columns img {
        height: 40.4rem; } }
  @media (min-width: 768px) {
    .three-columns figcaption {
      position: absolute;
      left: 100%;
      transform-origin: top left;
      transform: rotate(-90deg); } }
  .three-columns .backdrop {
    width: 100vw;
    top: 8.2rem;
    height: 24rem;
    left: -2.4rem; }
    @media (min-width: 768px) {
      .three-columns .backdrop {
        width: 161%;
        top: 16rem;
        height: 32rem;
        left: -6.5rem; } }

.two-columns {
  margin-top: 12rem; }
  .two-columns-inner {
    padding-top: 8rem; }
    .two-columns-cream .two-columns-inner,
    .two-columns-gray-green .two-columns-inner,
    .two-columns-bright-teal .two-columns-inner {
      padding-bottom: 8rem; }
    @media (max-width: 767.98px) {
      .two-columns-gray-pink .two-columns-inner {
        padding-top: 2rem; } }
  .two-columns .h3, .two-columns .kirbytext h1, .kirbytext .two-columns h1 {
    margin-bottom: 6rem; }
    @media (min-width: 768px) {
      .two-columns .h3, .two-columns .kirbytext h1, .kirbytext .two-columns h1 {
        margin-bottom: 9rem; } }
  @media (min-width: 768px) {
    .two-columns .kirbytext h2 {
      margin-bottom: 2.8rem; } }

.two-columns-cream .backdrop,
.two-columns-gray-green .backdrop,
.two-columns-bright-teal .backdrop {
  top: 0;
  height: 100%;
  width: 4.8rem; }
  @media (min-width: 768px) {
    .two-columns-cream .backdrop,
    .two-columns-gray-green .backdrop,
    .two-columns-bright-teal .backdrop {
      width: 30vw; } }

.two-columns-cream .backdrop,
.two-columns-bright-teal .backdrop {
  left: calc(50% - 50vw); }
  @media (min-width: 768px) {
    .two-columns-cream .backdrop,
    .two-columns-bright-teal .backdrop {
      left: auto;
      right: 90%; } }

.two-columns-gray-green .backdrop {
  right: calc(50% - 50vw); }
  @media (min-width: 768px) {
    .two-columns-gray-green .backdrop {
      left: 90%;
      right: auto; } }

.two-columns-gray-pink .backdrop {
  top: 0;
  right: 25%;
  width: 75vw;
  height: 4.8rem; }
  @media (min-width: 768px) {
    .two-columns-gray-pink .backdrop {
      right: 50%;
      width: 50vw;
      height: 27rem; } }

@media (max-width: 767.98px) {
  .staff {
    margin-top: 11.2rem; } }

.staff .backdrop {
  background: linear-gradient(to top, #d9dfd3 calc((50vw - 3.6rem) + 15.6rem), #f0ebdd calc((50vw - 3.6rem) + 15.6rem));
  position: absolute;
  top: calc((50vw - 3.6rem) * 0.5);
  bottom: calc((50vw - 3.6rem) * 0.5 + 11.6rem);
  width: 100vw;
  left: calc(50% - 50vw); }
  @media (min-width: 768px) {
    .staff .backdrop {
      background: linear-gradient(to left, #d9dfd3 27.4%, #f0ebdd 27.4%);
      width: auto;
      top: 7.05rem;
      bottom: 20.25rem;
      left: -5%;
      right: -5%; } }
  @media (min-width: 1280px) {
    .staff .backdrop {
      top: 10.5rem;
      bottom: 24.9rem; } }
  @media (min-width: 1580px) {
    .staff .backdrop {
      top: 11.85rem;
      bottom: 26.25rem; } }

.staff .h4, .staff .kirbytext h2, .kirbytext .staff h2, .staff label {
  margin: 1.6rem 0 0.8rem; }
  @media (min-width: 768px) {
    .staff .h4, .staff .kirbytext h2, .kirbytext .staff h2, .staff label {
      margin: 2.8rem 0 1rem; } }
  @media (max-width: 1279.98px) {
    .staff .h4, .staff .kirbytext h2, .kirbytext .staff h2, .staff label {
      font-size: 1.8rem;
      line-height: 1.4444444444; } }

.staff .small {
  min-height: 4rem; }

.staff .row {
  margin-left: -1.2rem;
  margin-right: -1.2rem; }
  @media (min-width: 768px) {
    .staff .row {
      margin-top: 6rem;
      margin-left: -3.65rem;
      margin-right: -3.65rem; } }
  @media (min-width: 1280px) {
    .staff .row {
      margin-left: -4.85rem;
      margin-right: -4.85rem; } }
  @media (min-width: 1580px) {
    .staff .row {
      margin-left: -5.25rem;
      margin-right: -5.25rem; } }

.staff .col-5 {
  padding: 0 1.2rem; }
  @media (min-width: 768px) {
    .staff .col-5 {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 3.65rem; } }
  @media (min-width: 1280px) {
    .staff .col-5 {
      padding: 0 4.85rem; } }
  @media (min-width: 1580px) {
    .staff .col-5 {
      padding: 0 5.25rem; } }
  @media (max-width: 767.98px) {
    .staff .col-5:nth-child(n + 3) {
      margin-top: 4rem; } }
  @media (min-width: 768px) {
    .staff .col-5:nth-child(n + 5) {
      margin-top: 8rem; } }

@media (min-width: 768px) {
  .staff figcaption {
    right: 0;
    bottom: calc(100% - 6rem);
    transform: rotate(-90deg);
    transform-origin: bottom right; } }

.heading-button {
  margin-top: 12rem; }
  .heading-button.style-peach .heading-button-inner {
    padding: 6rem 0 8rem; }
  .heading-button .backdrop {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, #f1d1b8 57.3333333333%, #f0ebdd 57.3333333333%); }
  .heading-button .h3, .heading-button .kirbytext h1, .kirbytext .heading-button h1 {
    margin-bottom: 0; }
  .heading-button.style-plain .h3, .heading-button.style-plain .kirbytext h1, .kirbytext .heading-button.style-plain h1 {
    margin-bottom: 4.8rem; }
  .heading-button.style-peach .btn {
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%); }

.accordion:not(:first-child) {
  margin-top: 12rem; }

.accordion .btn {
  position: relative;
  margin-right: 1.6rem; }
  @media (min-width: 768px) {
    .accordion .btn {
      margin-right: 2.8rem;
      top: 0.2rem; } }
  @media (max-width: 767.98px) {
    .accordion .btn {
      font-size: 2.8rem;
      top: -0.2rem; } }

.accordion-style-teal .btn:hover,
.accordion-style-teal .btn:focus,
.accordion-style-teal a:hover .btn,
.accordion-style-teal a:focus .btn {
  background: #acf9d3; }

.accordion [aria-expanded="true"] .btn {
  color: #fff !important;
  background: #343440 !important; }

.accordion .minus {
  display: none; }

.accordion [aria-expanded="true"] .minus {
  display: block; }

.accordion [aria-expanded="true"] .plus {
  display: none; }

.accordion .section:not(:last-child) {
  margin-bottom: 2.8rem; }
  @media (min-width: 768px) {
    .accordion .section:not(:last-child) {
      margin-bottom: 3.2rem; } }

.accordion .collapse-inner {
  padding: 1.6rem 0 0 4.4rem; }
  @media (min-width: 768px) {
    .accordion .collapse-inner {
      padding: 2.4rem 0 0 6.4rem; } }
  .accordion .collapse-inner::after {
    content: "";
    display: block;
    width: 12rem;
    height: 1px;
    background: #e8e7e3;
    margin-top: 2.4rem; }
    @media (min-width: 768px) {
      .accordion .collapse-inner::after {
        margin-top: 3.2rem; } }

.paragraph-text:not(:first-child) {
  margin-top: 12rem; }

.projects {
  margin-top: 8rem; }
  .projects:first-child {
    margin-top: -4rem; }
    @media (min-width: 768px) {
      .projects:first-child {
        margin-top: -25rem; } }
    @media (min-width: 1280px) {
      .projects:first-child {
        margin-top: -30.8rem; } }

.project {
  display: flex;
  margin-top: 12rem;
  justify-content: center; }
  .project .h3, .project .kirbytext h1, .kirbytext .project h1 {
    margin-top: 2.4rem; }
    @media (min-width: 768px) {
      .project .h3, .project .kirbytext h1, .kirbytext .project h1 {
        margin-top: 2.8rem; } }
    @media (min-width: 1280px) {
      .project .h3, .project .kirbytext h1, .kirbytext .project h1 {
        margin-top: 3.2rem; } }
    @media (max-width: 767.98px) {
      .project .h3, .project .kirbytext h1, .kirbytext .project h1 {
        line-height: 1.5;
        font-size: 2.4rem; } }
  .project figcaption {
    padding: 0.8rem 0 0; }
    @media (min-width: 768px) {
      .project figcaption {
        padding: 1.6rem 0 0; } }
    @media (min-width: 1280px) {
      .project figcaption {
        padding: 2rem 0 0; } }

@media (min-width: 768px) {
  .project-style-1 {
    justify-content: flex-end;
    margin-top: 16rem; } }

@media (min-width: 768px) {
  .project-style-1 figure {
    min-width: 52.9203539823%;
    max-width: 52.9203539823%;
    left: 7.0796460177%; } }

.project-style-1 img {
  height: 64vw; }
  @media (min-width: 768px) {
    .project-style-1 img {
      height: 30.58rem; } }
  @media (min-width: 1280px) {
    .project-style-1 img {
      height: 44rem; } }

.project-style-1 .backdrop {
  background: #d9dfd3;
  left: -2.4rem;
  right: -4rem;
  height: 42vw;
  bottom: -4.4rem; }
  @media (min-width: 768px) {
    .project-style-1 .backdrop {
      left: -10rem;
      right: 6.4rem;
      height: 28rem;
      bottom: -12rem; } }

.project-style-1 figcaption {
  color: #d59c85; }

@media (min-width: 768px) {
  .project-style-2 {
    justify-content: flex-start;
    margin-top: 16rem; } }

@media (min-width: 768px) {
  .project-style-2 figure {
    min-width: 38.9380530973%;
    max-width: 38.9380530973%;
    left: -6.814159292%; } }

.project-style-2 img {
  height: 87.2vw; }
  @media (min-width: 768px) {
    .project-style-2 img {
      height: 30.58rem; } }
  @media (min-width: 1280px) {
    .project-style-2 img {
      height: 44rem; } }

.project-style-2 .backdrop {
  background: #acf9d3;
  left: calc(100% - 5.6rem);
  right: -2.4rem;
  height: 8rem;
  bottom: -2.4rem; }
  @media (min-width: 768px) {
    .project-style-2 .backdrop {
      left: calc(100% - 8rem);
      right: -4rem;
      height: 12rem;
      bottom: -4rem; } }

.project-style-2 figcaption {
  color: #c1dfd5; }

@media (min-width: 768px) {
  .project-style-3 {
    justify-content: flex-end;
    margin-top: -25.6rem; } }

@media (min-width: 768px) {
  .project-style-3 figure {
    min-width: 38.9380530973%;
    max-width: 38.9380530973%;
    left: 0%; } }

.project-style-3 img {
  height: 114.933333333vw; }
  @media (min-width: 768px) {
    .project-style-3 img {
      height: 40.31rem; } }
  @media (min-width: 1280px) {
    .project-style-3 img {
      height: 58rem; } }

.project-style-3 .backdrop {
  background: #f1d1b8;
  left: -4rem;
  right: -7rem;
  height: 64vw;
  bottom: -4.4rem; }
  @media (min-width: 768px) {
    .project-style-3 .backdrop {
      left: -6rem;
      right: -90%;
      height: 40rem;
      bottom: -12rem; } }

.project-style-3 figcaption {
  color: #ee4622; }

@media (min-width: 768px) {
  .project-style-4 {
    justify-content: flex-start;
    margin-top: 19rem; } }

@media (min-width: 768px) {
  .project-style-4 figure {
    min-width: 59.2920353982%;
    max-width: 59.2920353982%;
    left: 7.0796460177%; } }

.project-style-4 img {
  height: 57.3333333333vw; }
  @media (min-width: 768px) {
    .project-style-4 img {
      height: 30.58rem; } }
  @media (min-width: 1280px) {
    .project-style-4 img {
      height: 44rem; } }

.project-style-4 .backdrop {
  background: #f0ebdd;
  left: -2.4rem;
  right: calc(100% - 11.6rem);
  height: 14rem;
  bottom: -4.4rem; }
  @media (min-width: 768px) {
    .project-style-4 .backdrop {
      left: -8rem;
      right: calc(100% - 20rem);
      height: 28rem;
      bottom: -12rem; } }

.project-style-4 figcaption {
  color: #ddb167; }

@media (min-width: 768px) {
  .project-style-5 {
    justify-content: flex-end;
    margin-top: 16rem; } }

@media (min-width: 768px) {
  .project-style-5 figure {
    min-width: 52.9203539823%;
    max-width: 52.9203539823%;
    left: 7.0796460177%; } }

.project-style-5 img {
  height: 64vw; }
  @media (min-width: 768px) {
    .project-style-5 img {
      height: 30.58rem; } }
  @media (min-width: 1280px) {
    .project-style-5 img {
      height: 44rem; } }

.project-style-5 .backdrop {
  background: #c5d8a7;
  left: -2.4rem;
  right: -4rem;
  height: 42vw;
  bottom: -4.4rem; }
  @media (min-width: 768px) {
    .project-style-5 .backdrop {
      left: -10rem;
      right: 6.4rem;
      height: 28rem;
      bottom: -12rem; } }

.project-style-5 figcaption {
  color: #77c68f; }

@media (min-width: 768px) {
  .project-style-6 {
    justify-content: flex-start;
    margin-top: 16rem; } }

@media (min-width: 768px) {
  .project-style-6 figure {
    min-width: 38.9380530973%;
    max-width: 38.9380530973%;
    left: -6.814159292%; } }

.project-style-6 img {
  height: 87.2vw; }
  @media (min-width: 768px) {
    .project-style-6 img {
      height: 30.58rem; } }
  @media (min-width: 1280px) {
    .project-style-6 img {
      height: 44rem; } }

.project-style-6 .backdrop {
  background: #ffef60;
  left: calc(100% - 5.6rem);
  right: -2.4rem;
  height: 8rem;
  bottom: -2.4rem; }
  @media (min-width: 768px) {
    .project-style-6 .backdrop {
      left: calc(100% - 8rem);
      right: -4rem;
      height: 12rem;
      bottom: -4rem; } }

.project-style-6 figcaption {
  color: #f9b58f; }

@media (min-width: 768px) {
  .project-style-7 {
    justify-content: flex-end;
    margin-top: -31.5rem; } }

@media (min-width: 768px) {
  .project-style-7 figure {
    min-width: 38.9380530973%;
    max-width: 38.9380530973%;
    left: 0%; } }

.project-style-7 img {
  height: 114.933333333vw; }
  @media (min-width: 768px) {
    .project-style-7 img {
      height: 40.31rem; } }
  @media (min-width: 1280px) {
    .project-style-7 img {
      height: 58rem; } }

.project-style-7 .backdrop {
  background: #e8e7e3;
  left: -4rem;
  right: -7rem;
  height: 64vw;
  bottom: -4.4rem; }
  @media (min-width: 768px) {
    .project-style-7 .backdrop {
      left: -6rem;
      right: -90%;
      height: 40rem;
      bottom: -12rem; } }

.project-style-7 figcaption {
  color: #ac5a50; }

@media (min-width: 768px) {
  .project-style-8 {
    justify-content: flex-start;
    margin-top: 19rem; } }

@media (min-width: 768px) {
  .project-style-8 figure {
    min-width: 59.2920353982%;
    max-width: 59.2920353982%;
    left: 7.0796460177%; } }

.project-style-8 img {
  height: 57.3333333333vw; }
  @media (min-width: 768px) {
    .project-style-8 img {
      height: 30.58rem; } }
  @media (min-width: 1280px) {
    .project-style-8 img {
      height: 44rem; } }

.project-style-8 .backdrop {
  background: #c1dfd5;
  left: -2.4rem;
  right: calc(100% - 11.6rem);
  height: 14rem;
  bottom: -4.4rem; }
  @media (min-width: 768px) {
    .project-style-8 .backdrop {
      left: -8rem;
      right: calc(100% - 20rem);
      height: 28rem;
      bottom: -12rem; } }

.project-style-8 figcaption {
  color: #2b40b7; }

@media (min-width: 1580px) {
  .pages-project .hero h1 {
    width: 67rem; } }

.project-details .backdrop {
  right: 0;
  left: -50%;
  bottom: 0;
  height: calc(100% + 11rem); }
  @media (min-width: 768px) {
    .project-details .backdrop {
      left: -7.5rem;
      height: calc(100% + 27rem); } }
  @media (min-width: 1280px) {
    .project-details .backdrop {
      height: calc(100% + 30rem); } }

.project-summary {
  margin-top: 8rem; }
