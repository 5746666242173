.two-columns {
  margin-top: 12rem;

  &-inner {
    padding-top: 8rem;

    .two-columns-cream &,
    .two-columns-gray-green &,
    .two-columns-bright-teal & {
      padding-bottom: 8rem;
    }

    .two-columns-gray-pink & {
      @include xs {
        padding-top: 2rem;
      }
    }
  }

  .h3 {
    margin-bottom: 6rem;

    @include md {
      margin-bottom: 9rem;
    }
  }

  .kirbytext {
    h2 {
      @include md {
        margin-bottom: 2.8rem;
      }
    }
  }
}

.backdrop {
  .two-columns-cream &,
  .two-columns-gray-green &,
  .two-columns-bright-teal & {
    top: 0;
    height: 100%;
    width: 4.8rem;

    @include md {
      width: 30vw;
    }
  }

  .two-columns-cream &,
  .two-columns-bright-teal & {
    left: calc(50% - 50vw);

    @include md {
      left: auto;
      right: 90%;
    }
  }

  .two-columns-gray-green & {
    right: calc(50% - 50vw);

    @include md {
      left: 90%;
      right: auto;
    }
  }

  .two-columns-gray-pink & {
    top: 0;
    right: 25%;
    width: 75vw;
    height: 4.8rem;

    @include md {
      right: 50%;
      width: 50vw;
      height: 27rem;
    }
  }
}
