.form-control,
.custom-select {
  border-bottom-width: $border-width;
}

label {
  @extend .h4;

  margin-bottom: $label-margin-bottom !important;
}

select:invalid {
  color: $input-placeholder-color;
}

.page-contact {
  main {
    &::before {
      content: none;
    }
  }
}

form {
  .btn-wrap {
    margin-top: 6rem;
  }

  [type="submit"] {
    position: relative;

    &::before,
    &::after {
      @include transition(opacity $t);

      content: "";
      pointer-events: none;
      opacity: 0;
      position: absolute;
    }

    &::before {
      z-index: 1;
      left: -$border-width;
      right: -$border-width;
      top: -$border-width;
      bottom: -$border-width;
      background: rgba($body-bg, 0.7);
    }

    &::after {
      @extend .spinner-border;

      color: $secondary;
      z-index: 2;
      left: calc(50% - 2rem);
      top: calc(50% - 2rem);
      width: 4rem;
      height: 4rem;
    }
  }
}

.form-busy {
  [type="submit"] {
    &::before,
    &::after {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.msg {
  display: none;
  margin-top: 6rem;
}

.msg-success {
  .form-success & {
    display: block;
  }
}

.msg-error {
  .form-error & {
    display: block;
  }
}

.contact-form {
  margin-top: 12rem;
  padding: 6rem 0;

  @include md {
    padding: 8rem 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .backdrop {
    left: -50%;
    top: 0;
    bottom: 0;
    right: 90%;
    background: linear-gradient(to bottom, $beige 77%, $gray-green 77%);
  }

  figcaption {
    position: absolute;
    top: 9.2rem;
    left: calc(50% - 50vw);
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100%);
  }

  h2 {
    margin-bottom: 6rem;

    @include md {
      margin-bottom: 10rem;
    }
  }

  .invalid-feedback {
    width: auto;
  }

  .was-validated .invalid-feedback {
    display: block;
  }
}
