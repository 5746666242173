.three-columns {
  margin-top: 12rem;

  @include md {
    .col-md-3 {
      &:nth-of-type(2) {
        padding-top: 12rem;
      }

      &:last-of-type {
        padding-top: 24rem;
      }
    }
  }

  @include xs {
    .col-md-3 + .col-md-3 {
      margin-top: 7.2rem;
    }
  }

  figure {
    @include md {
      margin-top: 6.3rem;
      margin-left: -20%;
      margin-right: -2rem;
      margin-bottom: 10rem;
    }
  }

  img {
    height: 28.2rem;
    width: 100%;

    @include md {
      height: 40.4rem;
    }
  }

  figcaption {
    @include md {
      position: absolute;
      left: 100%;
      transform-origin: top left;
      transform: rotate(-90deg);
    }
  }

  .backdrop {
    width: 100vw;
    top: 8.2rem;
    height: 24rem;
    left: -2.4rem;

    @include md {
      width: 161%;
      top: 16rem;
      height: 32rem;
      left: -6.5rem;
    }
  }
}
